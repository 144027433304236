import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import CartRepository from "../../data/repository/CartRepository";
import { useSearchParams } from 'react-router-dom';

const CartContext = createContext();

export const useCart = () => {
    return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
    const [cartList, setCartList] = useState([]);
    const cartRepository = useMemo(() => new CartRepository(), []);
    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.get("referrer")){
        sessionStorage.setItem("referrer", searchParams.get("referrer"));
    }

    const getCartList = useCallback(async () => {
        try {
            const data = await cartRepository.getList();
            setCartList(data);
        } catch (error) {
            console.error("Error obteniendo la lista del carrito:", error);
        }
    }, [cartRepository, setCartList]);

    const addProduct = async (productId, tokenId) => {
        try {
            const response = await cartRepository.addProduct(productId, tokenId);
            if (response.success) {
                getCartList();
            }
        } catch (error) {
            console.error("Error al añadir el producto:", error);
        }
    };

    const subtractProduct = async (productId, tokenId) => {
        try {
            const response = await cartRepository.subtractProduct(productId, tokenId);
            if (response.success) {
                getCartList();
            }
        } catch (error) {
            console.error("Error al restar el producto:", error);
        }
    };

    const emptyCart = async () => {
        try {
            const response = await cartRepository.empty();
            if (response.success) {
                getCartList();
            }
        } catch (error) {
            console.error("Error al vaciar carrito:", error);
        }
    };

    const value = {
        cartList,
        getCartList,
        addProduct,
        subtractProduct,
        emptyCart
    };

    return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

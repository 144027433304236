import React from "react";
import WaitPaymentComponent from "../components/checkout/WaitPaymentComponent";


const WaitPaymentView = () => {

    return (
        <div>
            <WaitPaymentComponent />
        </div>
    );
}

export default WaitPaymentView;
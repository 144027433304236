import React, { useState, useEffect } from "react";
import {
  Menu,
  Button,
  MenuItem,
  Divider,
  ListItemText,
  IconButton,
  ListItemAvatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Cart from "../../assets/images/Cart.png";
import CartDark from "../../assets/images/cart-dark.png";
import { useCart } from "../contexts/CartContext";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const CartIconComponent = ({ isDarkTheme, textColor }) => {
  const { t } = useTranslation();
  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const { cartList, getCartList, addProduct, subtractProduct, emptyCart } =
    useCart();
  const { isAuthenticated, setIsAuthenticated, roles } = useAuth();

  const handleCartClick = (event) => {
    if (isAuthenticated) {
      getCartList();
      setCartAnchorEl(event.currentTarget);
    }
  };

  const handleCartClose = () => {
    setCartAnchorEl(null);
  };

  const handleAddProduct = async (productId) => {
    addProduct(productId);
  };

  const handleSubtractProduct = async (productId, tokenId) => {
    subtractProduct(productId, tokenId);
  };

  const handleEmptyCart = async () => {
    emptyCart();
  };

  return (
    <>
      <Button onClick={handleCartClick}>
        {cartList?.products?.reduce((n, { quantity }) => n + quantity, 0) ? (
          <div>
            {cartList?.products?.reduce((n, { quantity }) => n + quantity, 0)}
          </div>
        ) : null}
        <img
          src={isDarkTheme ? Cart : CartDark}
          alt="Cart"
          style={{ width: "25.51px" }}
        />
      </Button>
      <Menu
        anchorEl={cartAnchorEl}
        keepMounted
        open={Boolean(cartAnchorEl)}
        onClose={handleCartClose}
        PaperProps={{
          style: {
            backgroundColor: isDarkTheme ? "#160F31" : "white",
          },
        }}
      >
        {cartList?.products?.length > 0 ? (
          [
            ...cartList?.products?.map((item, index) => (
              <MenuItem key={index} sx={{ gap: "0.25rem" }}>
                <ListItemAvatar>
                  <img
                    src={item.thumbnail}
                    alt={item.name}
                    style={{ height: "60px" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    style: {
                      color: textColor,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    },
                  }}
                  secondary={`Precio: ${
                    item.local_symbol
                  }${item.total_local.toFixed(2)}`}
                  secondaryTypographyProps={{ style: { color: textColor } }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      handleSubtractProduct(item.id, item.token?.id)
                    }
                    style={{ color: "#F36E1E" }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <span style={{ color: textColor }}>{item.quantity}</span>
                  {item.token ? null : (
                    <IconButton
                      color="primary"
                      onClick={() => handleAddProduct(item.id)}
                      style={{ color: "#F36E1E" }}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </div>
              </MenuItem>
            )),
            <Divider key="divider" />,
            <MenuItem key="actions" style={{ justifyContent: "center" }}>
              <Button
                variant="outlined"
                onClick={() => handleEmptyCart()}
                className={
                  isDarkTheme
                    ? "gradient-border-button"
                    : "btn-outline-gradient"
                }
                style={{ color: textColor }}
              >
                {t("globals.navbar.emptyCart")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px" }}
                className={"btn-gradient"}
                href="/checkout"
              >
                {t("globals.navbar.checkout")}
              </Button>
            </MenuItem>,
          ]
        ) : (
          <MenuItem style={{ justifyContent: "center", color: textColor }}>
            {t("globals.navbar.noProducts")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default CartIconComponent;

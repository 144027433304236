import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import ProductRepository from "../../../data/repository/ProductRepository";
import UserRequestAllSeries from "../../../core/entity/UserRequestAllSeries";
import SuccessDialog from "../SuccessDialog";


const DialogRequestAllSeries = ({ open, onClose, productId, productName }) => {

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        email: ''
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const repository = new ProductRepository();
    const [success, setSuccess] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const userRequest = new UserRequestAllSeries(formData.email);
        let validationErrors = userRequest.validate(formData);

        setFieldErrors({})
        if (Object.keys(validationErrors).length > 0) {
            setFieldErrors(validationErrors);
            return;
        }

        try {
            setFieldErrors({})
            const response = await repository.requestBuyAllSeries(formData, productId, productName);

            if (response.success) {
                setSuccess("Tu solicitud ha sido enviada con éxito.");
                onClose();
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error(error.message);
        }
    };


    return (
        <>
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogContent>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }} gutterBottom>
                        {t('dialogRequest.title')}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        {t('dialogRequest.sentence1')}
                    </Typography>

                    <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '1rem' }}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className={"dark-border"}
                            InputProps={{
                                style: {
                                    backgroundColor: 'transparent',
                                    borderRadius: '55px',
                                }
                            }}
                            helperText={fieldErrors.email}
                            error={Boolean(fieldErrors.email)}
                        />

                        <Button
                            type={'submit'}
                            variant="outlined"
                            className={"btn-gradient"}
                            style={{ width: '100%', marginTop: '1rem' }}
                        >
                            {t('dialogRequest.sentence2')}
                        </Button>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
        <SuccessDialog
            open={!!success}
            onClose={() => setSuccess(null)}
            alertTitle={t('dialogRequest.congrats')}
            alertMessage={success}
            dark={true}
        />
        </>
    );
};

export default DialogRequestAllSeries;
import React from "react";
import ProductDetailComponent from "../components/marketplace/ProductDetailComponent";


const DetailProductView = () => {

    return (
        <div>
            <ProductDetailComponent />
        </div>
    );
}

export default DetailProductView;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Button, Typography, Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoginModal from './LoginModal';

const CallToActionRegister = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  return (
    <Container maxWidth={'xl'}>
      <Box
        mt={15}
        mb={15}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={4}
        style={{
          background:
            'linear-gradient(90deg, transparent, #F47F26, transparent)'
        }}
      >
        <Grid container xs={1}></Grid>
        <Grid container xs={10}>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h3"
              sx={{ color: 'white', fontSize: { xs: '16px', md: '24px' } }}
            >
              {t('home.callToAction1.title')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-end' },
              alignItems: 'center',
              marginTop: { xs: '1rem', sm: '0' }
            }}
          >
            <Button
              variant="outlined"
              onClick={
                !isAuthenticated
                  ? () => setLoginModalOpen(true)
                  : () => navigate('/profile/user-data')
              }
              style={{
                color: 'white',
                borderColor: 'white',
                backgroundColor: 'transparent',
                padding: '10px 30px',
                borderRadius: '55px'
              }}
            >
              {!isAuthenticated
                ? t('home.callToAction1.button')
                : t('home.callToAction1.buttonAlt')}
            </Button>
          </Grid>
        </Grid>
        <Grid container xs={1}></Grid>
      </Box>
      {!isAuthenticated ? (
        <LoginModal
          open={loginModalOpen}
          onClose={() => setLoginModalOpen(null)}
          theme={'dark'}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

export default CallToActionRegister;

import React from "react";
import UserDataComponent from "../components/profile/UserDataComponent";


const UserDataView = () => {

    return (
        <div>
            <UserDataComponent />
        </div>
    );
}

export default UserDataView;
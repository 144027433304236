import React from "react";
import MarketplaceBenefitsComponent from "../components/marketplace/MarketplaceBenefitsComponent";

const MarketplaceBenefitsView = () => {

    return (
        <div>
            <MarketplaceBenefitsComponent/>
        </div>
    );
}

export default MarketplaceBenefitsView;
import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MyAccountMenuComponent({
  isDarkTheme,
  textColor,
  toggleMobileDrawer,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isAuthenticated, roles } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ color: textColor }}
        onClick={handleClick}
        className={isDarkTheme ? "gradient-border-button" : "btn-gradient"}
      >
        {t("globals.navbar.miCuenta")}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: isDarkTheme ? "#160F31" : "white",
          },
        }}
      >
        <MenuItem
          component={Link}
          to="/profile/user-data"
          style={{ color: textColor }}
          onClick={toggleMobileDrawer}
        >
          {t("globals.navbar.miPerfil")}
        </MenuItem>
        {roles.includes("ROLE_USER") ? (
          <>
            <MenuItem
              component={Link}
              to="/profile/tokens"
              style={{ color: textColor }}
              onClick={toggleMobileDrawer}
            >
              {t("globals.navbar.misWineNFTs")}
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile/points"
              style={{ color: textColor }}
              onClick={toggleMobileDrawer}
            >
              {t("globals.navbar.misPuntos")}
            </MenuItem>
            <MenuItem
              component={Link}
              to="/profile/my-benefits"
              style={{ color: textColor }}
              onClick={toggleMobileDrawer}
            >
              {t("globals.navbar.misBeneficios")}
            </MenuItem>
          </>
        ) : (
          <>
            {/*<MenuItem
                            component={Link}
                            to="/profile/points"
                            style={{ color: textColor }}
                            onClick={toggleMobileDrawer}
                        >
                            {t('globals.navbar.points')}
                        </MenuItem>*/}
            {roles.includes("ROLE_COMMERCE") ? (
              <>
                <MenuItem
                  component={Link}
                  to="/profile/benefits"
                  style={{ color: textColor }}
                  onClick={toggleMobileDrawer}
                >
                  {t("globals.navbar.benefits")}
                </MenuItem>
              </>
            ) : null}
            {roles.includes("ROLE_COMMERCE") ||
            roles.includes("ROLE_CELLAR") ? (
              <>
                <MenuItem
                  component={Link}
                  to="/profile/reports"
                  style={{ color: textColor }}
                  onClick={toggleMobileDrawer}
                >
                  {t("globals.navbar.reports")}
                </MenuItem>
              </>
            ) : null}
            {roles.includes("ROLE_FINANCIAL") ? (
              <>
                <MenuItem
                  component={Link}
                  to="/profile/financial-collaterals"
                  style={{ color: textColor }}
                  onClick={toggleMobileDrawer}
                >
                  {t("globals.navbar.collaterals")}
                </MenuItem>
              </>
            ) : null}
            {roles.includes("ROLE_CELLAR") ? (
              <>
                <MenuItem
                  component={Link}
                  to="/profile/winery-collaterals"
                  style={{ color: textColor }}
                  onClick={toggleMobileDrawer}
                >
                  {t("globals.navbar.collaterals")}
                </MenuItem>
              </>
            ) : null}
          </>
        )}
        <MenuItem component={Link} to="/logout" style={{ color: textColor }}>
          {t("globals.navbar.logout")}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MyAccountMenuComponent;

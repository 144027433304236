import api from "../api";
import apiToken from "../apiToken";
class ProductRepository {
  async getProductsFiltered(params = {}) {
    try {
      const filteredParams = Object.entries(params).reduce(
        (acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const endpoint = urlParams ? `/marketplace?${urlParams}` : "/marketplace";

      const response = await api.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findById(id, language) {
    try {
      const response = await api.get("/product/detail/" + id, {
        headers: {
          "x-locale": language
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async requestBuyAllSeries(UserRequestAllSeries, productId, productName) {
    try {
      const response = await api.post("/request/buy/series", {
        userEmail: UserRequestAllSeries.email,
        productId: productId,
        productName: productName
      });

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error subscribing the user:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async findUserTokens() {
    try {
      const response = await apiToken.get("user/tokens");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findFinancialCollaterals(page) {
    try {
      const allParams = {
        page: page
      };
      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );
      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `profile/financial/collaterals?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findWineryCollaterals(page) {
    try {
      const allParams = {
        page: page
      };
      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );
      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `profile/cellar/collaterals?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getCollateralsCellars() {
    try {
      const response = await apiToken.get(
        "/profile/cellar/collaterals/purchased"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ProductRepository;

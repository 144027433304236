import React, {useState} from 'react';
import { Grid, Box, Typography, TextField, Button } from '@mui/material';
import Logo from '../../assets/images/LOGO DRINKSIFY.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactRepository from "../../data/repository/ContactRepository";
import ContactForm from "../../core/entity/ContactForm";
import ErrorDialog from "./ErrorDialog";
import SuccessDialog from "./SuccessDialog";
import { useTranslation } from 'react-i18next';
import PoweredBy from '../../assets/images/powered-by.png'

const FooterComponent = () => {

    // eslint-disable-next-line
    const [t, i18n] = useTranslation();
    
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const repository = new ContactRepository();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const contactForm = new ContactForm(email, message);
            const response = await repository.contact(contactForm);

            if (response.success) {
                setEmail('');
                setMessage('')
                setError(null);
                setSuccess(response.data.message);
            } else {
                setError(response.message);
            }
        } catch (error) {
            setError(error.message);
        }
    }

    return (
        <Box p={5} color="white">
            <Grid container spacing={4} >

                <Grid item xs={12} sm={4} container direction="column" alignItems="center" >
                    <img src={Logo} alt="Logo" style={{ width: '220px', marginBottom: '20px' }} />
                    <Typography variant="body1" align="center" className={"text-gradient"} style={{ fontWeight: 'bold' }} gutterBottom>
                        {t('globals.footer.tokenizedPremiumWines')}
                    </Typography>
                    <Box display="flex" gap={2} mt={2}>
                        <FacebookIcon style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.facebook.com/Drinksify.ok", "_blank")} />
                        <InstagramIcon style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.instagram.com/drinksifyok/", "_blank")} />
                        <LinkedInIcon style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.linkedin.com/company/drinksify", "_blank")} />
                    </Box>
                    <Box display="flex" gap={2} mt={4}>
                        <Typography variant="body1" align="center" className={"text-gradient"} sx={{ cursor: 'pointer', "&:hover": { fontWeight: "bold", textDecoration: "underline" } }} gutterBottom
                            onClick={ () => i18n.changeLanguage("es") }
                        >
                            ES
                        </Typography>
                        <Typography variant="body1" align="center" className={"text-gradient"} style={{ fontWeight: 'bold' }} gutterBottom>
                            |
                        </Typography>
                        <Typography variant="body1" align="center" className={"text-gradient"} sx={{ cursor: 'pointer', "&:hover": { fontWeight: "bold", textDecoration: "underline" } }} gutterBottom                            
                            onClick={ () => i18n.changeLanguage("en") }
                        >
                            EN
                        </Typography>
                    </Box>
                    
                </Grid>

                <Grid item xs={4} container direction="column" alignItems="center" spacing={1} sx={{display: {xs: 'none', sm: 'flex'}}}>
                    <Typography component="a" href="/about#como-funciona" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.comoFunciona')}</Typography>
                    <Typography component="a" href="/marketplace-benefits" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.beneficios')}</Typography>
                    <Typography component="a" href="/marketplace" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.marketplace')}</Typography>
                    <Typography component="a" href="/about#preguntas-frecuentes" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.preguntasFrecuentes')}</Typography>
                    {/*<br></br>*/}
                    <Typography component="a" href="/terms" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.terminos')}</Typography>
                    <Typography component="a" href="/policy" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.politica')}</Typography>
                    <Typography component="a" href="/cookies" sx={{ fontSize: '14px', color: '#FFFFFF', textDecoration: 'none', "&:hover": { textDecoration: 'underline'} }}>{t('globals.footer.cookies')}</Typography>
                    {/*<Typography component="a" href="#centro-de-ayuda" style={{ color: '#FFFFFF', textDecoration: 'none' }}>{t('globals.footer.centroDeAyuda')}</Typography>*/}
                </Grid>

                <Grid item xs={4} container direction="column" spacing={2} sx={{display: {xs: 'none', sm: 'block'}}}>
                    <Typography variant="h6" mb={0.5}>
                        {t('globals.footer.tienesDuda')}
                    </Typography>
                    <Typography variant="body2" gutterBottom mb={2}>
                        {t('globals.footer.estamosParaAyudarte')}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="white-border"
                            variant="outlined"
                            placeholder={t('globals.footer.email')}
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white',
                                    borderRadius: '55px',
                                    borderColor: 'white',
                                    maxHeight: '48px'
                                }
                            }}
                            mb={1}
                        />

                        <TextField
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="white-border"
                            variant="outlined"
                            placeholder={t('globals.footer.mensaje')}
                            fullWidth
                            multiline
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                            InputProps={{
                                style: {
                                    color: 'white',
                                    borderRadius: '55px',
                                    borderColor: 'white',
                                    maxHeight: '48px'
                                }
                            }}
                            mb={1}
                        />

                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                type="submit"
                                variant="outlined"
                                style={{
                                    color: 'white',
                                    borderColor: 'white',
                                    backgroundColor: 'transparent',
                                    padding: '10px 30px',
                                    borderRadius: '55px',
                                }}>
                                {t('globals.footer.enviar')}
                            </Button>
                        </Box>
                    </form>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" sx={{ gap: '1rem'}}>
                <Typography variant="body2" style={{ fontStyle: 'italic', textAlign: 'center' }}>
                    {t('globals.footer.copyright')}
                </Typography>
                <img src={PoweredBy} alt={`poweredby`} style={{width: '100px'}} />
            </Box>

            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={t('globals.footer.errorFormularioContacto')}
                alertMessage={error}
                dark={true}
            />

            <SuccessDialog
                open={!!success}
                onClose={() => setSuccess(null)}
                alertTitle={t('globals.footer.enhorabuena')}
                alertMessage={success}
                dark={true}
            />
        </Box>
    );
}

export default FooterComponent;

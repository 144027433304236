import React from "react";
import UserTokensComponent from "../components/profile/UserTokensComponent";


const UserTokensView = () => {

    return (
        <div>
            <UserTokensComponent />
        </div>
    );
}

export default UserTokensView;
import api from "../api";
class CellarRepository {
  async getCellarList() {
    try {
      const response = await api.get("/cellar/list");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default CellarRepository;

import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Hero from "../components/Hero";
import FooterComponent from "../components/FooterComponent";
import backgroundImage from "../../assets/images/FONDO-ABOUT.png";
import WhatWeDo from "../components/WhatWeDo";
import TeamComponent from "../components/TeamComponent";
import CallToActionRegister from "../components/CallToActionRegister";
import HowItWorks from "../components/HowItWorks";
import FaqsComponent from "../components/FaqsComponent";
import { useTranslation } from 'react-i18next';

const AllAboutView = () => {
    const { t } = useTranslation();

    const {hash, key} = useLocation();

    useEffect(() => {
        if (hash) {
            const targetElement = document.getElementById(hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [key, hash]);

    return (
        <div>
            <Hero
                subtitle={t("about.hero.title")}
                backgroundImage={backgroundImage}
            />
            <WhatWeDo />
            <TeamComponent />
            <HowItWorks />
            <CallToActionRegister />
            <FaqsComponent />
            <FooterComponent />
        </div>
    )
}

export default AllAboutView;
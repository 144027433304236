import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, Container, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';


const CallToActionBenefits = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Container maxWidth={'xl'}>
        <Box
            mt={15} mb={15}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={4}
            style={{
                background: 'linear-gradient(90deg, transparent, #F47F26, transparent)'
            }}
        >
            <Grid container xs={1}></Grid>
                    <Typography variant="h3" sx={{textAlign: 'center', color: 'white', fontSize: { xs: '16px', md: '24px' }}}>
                        {t('benefitsBlue.cta')}
                    </Typography>
            <Grid container xs={1}></Grid>
        </Box>
        </Container>
    );
}

export default CallToActionBenefits;
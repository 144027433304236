import api from "../api";
class OriginRepository {

    async getOriginList() {
        try {
            const response = await api.get('/origin/list');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default OriginRepository;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import Video from '../../assets/videos/dkf-how-buy-webm.webm';
import { useTranslation } from 'react-i18next';

const HomeTutorial = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <Container maxWidth="xl">
            <Grid container spacing={4} alignItems={"center"} mt={15} mb={15}>

                <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
                    <Box display="flex" justifyContent="center">
                        <video style={{width: '90%'}} controls>
                            <source src={Video}/>
                        </video>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: { xs: '20px', sm: '40px' }, fontWeight: 'bold', textAlign: { xs: 'center', sm: 'left' }}} gutterBottom>
                        {t('home.tutorial.title')}
                    </Typography>
                    <Typography variant="body1" sx={{mt: 4, color: 'white', fontSize: { xs: '14px', sm: '20px' }, textAlign: { xs: 'center', sm: 'left' }}} gutterBottom>
                        {t('home.tutorial.subtitle')}
                    </Typography>
                </Grid>

                <Grid item xs={12} order={3} display="flex" justifyContent="center">
                    <Button sx={{mt:3}} variant="contained" className={'btn-gradient'} onClick={ () => navigate("/about")}>
                        {t('home.tutorial.button')}
                    </Button>
                </Grid>

            </Grid>
        </Container>
    );
};

export default HomeTutorial;
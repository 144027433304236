import api from "../api";
class NewsRepository {
  async getNewsList() {
    try {
      const response = await api.get("/news");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default NewsRepository;

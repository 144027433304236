import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const AgeVerificationDialog = () => {
      
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isAgeVerified = localStorage.getItem('ageVerified');
    if (!isAgeVerified) {
      setIsOpen(true);
    }
  }, []);

  const handleConfirm = () => {
    localStorage.setItem('ageVerified', 'true');
    setIsOpen(false);
  };

  const handleDeny = () => {
    window.location.href = "https://www.google.com";
  };

  return (
      <Dialog open={isOpen} onClose={handleDeny} aria-labelledby="age-verification-title">
        <DialogTitle sx={{ backgroundColor: '#160F31', color: 'white' }} id="age-verification-title">
          {t('ageVerification.title')}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#160F31', color: 'white' }}>
          {t('ageVerification.sentence1')}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row'}, gap: '0.75rem', backgroundColor: '#160F31', justifyContent: 'space-between' }}>
          <Button sx={{ width: { xs: '100%', sm: '50%' }}} onClick={handleDeny} className={"btn-gradient"}>
            {t('ageVerification.sentence2')}
          </Button>
          <Button sx={{ width: { xs: '100%', sm: '50%' }}} onClick={handleConfirm} className={"gradient-border-button"} style={{color: 'white'}}>
            {t('ageVerification.sentence3')}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default AgeVerificationDialog;

import api from "../api";

class SubscriberRepository {
    async subscribe(subscriber) {
        try {
            const response = await api.post('/subscribe/user', { email: subscriber.email });
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error subscribing the user:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }
}

export default SubscriberRepository;
import React from "react";
import { Typography, Grid, Container, Card, Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import Benefits1 from "../../assets/images/benefits1.png";
import Benefits2 from "../../assets/images/benefits2.png";

const BenefitsTitle = () => {
  const { t } = useTranslation();

  const images = [
    {
      src: Benefits1,
      title: t("benefitsBlue.card1Title"),
      paragraph: t("benefitsBlue.card1Text"),
      link: "/marketplace-benefits",
    },
    {
      src: Benefits2,
      title: t("benefitsBlue.card2Title"),
      paragraph: t("benefitsBlue.card2Text"),
      link: "/benefits",
    },
  ];

  return (
    <Container maxWidth="xl">
      <Grid
        container
        mt={2}
        mb={12}
        display="flex"
        flexDirection="column"
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "16px", sm: "32px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("benefitsBlue.subtitle1")}
          </Typography>
        </Grid>

        <Grid item mb={3}>
          <Typography
            variant="body1"
            sx={{ color: "white", fontSize: { xs: "12px", sm: "16px" } }}
            align="center"
          >
            {t("benefitsBlue.text1")}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "16px", sm: "32px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("benefitsBlue.subtitle2")}
          </Typography>
        </Grid>

        <Grid item mb={8}>
          <Typography
            variant="body1"
            sx={{ color: "white", fontSize: { xs: "12px", sm: "16px" } }}
            align="center"
          >
            {t("benefitsBlue.text2")}
          </Typography>
        </Grid>

        <Container
          component="div"
          sx={{
            display: "flex",
            width: "100%",
            padding: "0rem !important",
            flexWrap: "wrap",
            alignContent: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: "2rem",
            justifyContent: { xs: "center", sm: "space-around" },
            alignItems: "center",
          }}
        >
          {images?.map((image, index) => (
            <Card
              key={index}
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "380px",
                alignSelf: "stretch",
              }}
              style={{
                backgroundColor: "transparent",
                background: "linear-gradient(to right, #FC6964, #FCC442)",
                padding: "2px",
                boxSizing: "border-box",
                borderRadius: "10px",
                overflow: "visible",
              }}
            >
              <Box
                style={{
                  backgroundColor: "#160F31",
                  padding: "2rem",
                  width: "calc(100% - 4rem)",
                  height: "calc(100% - 4rem)",
                  borderRadius: "7px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0rem",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={image.src}
                  alt={`Icon ${index}`}
                  style={{ objectFit: "cover" }}
                />
                <Typography
                  variant="h5"
                  gutterBottom
                  align="start"
                  mt={3}
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: "25px",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {image.title}
                </Typography>
                <Typography
                  variant="body2"
                  align="start"
                  mt={2}
                  mb={2}
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  {image.paragraph}
                </Typography>
                <Link
                  href={index ? null : image.link}
                  className={"btn-gradient"}
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    bottom: "-20px",
                    left: index ? "calc(50% - 100px)" : "calc(50% - 72px)",
                    textDecoration: "none",
                  }}
                >
                  {index
                    ? t("marketplaceBenefits.subtitle2OnProgress")
                    : t("benefitsBlue.access")}
                </Link>
              </Box>
            </Card>
          ))}
        </Container>
      </Grid>
    </Container>
  );
};

export default BenefitsTitle;

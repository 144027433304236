import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, Button, Container, OutlinedInput, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserRepository from "../../../data/repository/UserRepository";
import ErrorDialog from "../ErrorDialog";
import ChangePassword from "../../../core/entity/ChangePassword";

const RestorePassword = () => {

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [error, setError] = useState(null);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const navigate = useNavigate();
    const repository = new UserRepository();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');


    const handleSubmit = async (e) => {
        e.preventDefault();

        const changePassword = new ChangePassword(formData)
        let validationErrors = changePassword.validatePassword(formData);

        if (Object.keys(validationErrors).length > 0) {
            setFieldErrors(validationErrors);
            return;
        }

        try {
            setFieldErrors({})
            const response = await repository.changePassword(uid, token, changePassword.password);

            if (response.success) {
                setError(null);
                await navigate('/', {
                    state: {
                        showMessage: true,
                        message: 'Se ha cambiado la contraseña correctamente',
                    },
                });
            } else {
                setError(response.message);
            }
        } catch (error) {
            setError(error.message);
        }

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <Container>
            <Typography variant="h3" style={{color: '#F36E1E', fontSize: '35px', fontWeight: 'bold'}} align="center" mt={10} mb={12}>
                {t('changePassword.form')}
            </Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
                        <VisibilityIcon onClick={ () => { setShowPassword1(!showPassword1)}} sx={{ cursor: 'pointer', zIndex: '2', position: 'absolute', top: '39px', right: '24px', color: '#F36E1E' }}/>
                        <TextField fullWidth type={ showPassword1 ? 'text' : 'password' } label="Password" name="password" value={formData.password} onChange={handleInputChange} InputProps={{style: {borderRadius: '55px'}}} helperText={fieldErrors.password} error={Boolean(fieldErrors.password)} />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
                        <VisibilityIcon onClick={ () => { setShowPassword2(!showPassword2)}} sx={{ cursor: 'pointer', zIndex: '2', position: 'absolute', top: '39px', right: '24px', color: '#F36E1E' }}/>
                        <TextField fullWidth type={ showPassword2 ? 'text' : 'password' } label="Confirmar Password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} InputProps={{style: {borderRadius: '55px'}}} />
                    </Grid>

                    <Grid item xs={12} container justifyContent="flex-start">
                        <Button type="submit" variant="contained" className={"btn-gradient"}>
                            {t('changePassword.change')}
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={'Error al restablecer'}
                alertMessage={error}
                dark={false}
            />
        </Container>
    );
}

export default RestorePassword;
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from 'react-router-dom';


function RouteAnonymousUser({children}) {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return children;
}

export default RouteAnonymousUser;

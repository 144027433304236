import React from "react";
import CartCheckout from "../components/checkout/CartCheckout";


const CheckoutView = () => {

    return (
        <div>
            <CartCheckout />
        </div>
    );
}

export default CheckoutView;
import React from "react";
import UserPointsComponent from "../components/profile/UserPointsComponent";


const UserPointsView = () => {

    return (
        <div>
            <UserPointsComponent />
        </div>
    );
}

export default UserPointsView;
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorDialog = ({
  open,
  onClose,
  alertTitle,
  alertMessage,
  dark,
  typeOfProduct,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={dark ? "dk-dialog-dark" : "dk-dialog"}
    >
      <DialogTitle id="dk-dialog-title">{alertTitle}</DialogTitle>
      <DialogContent id="dk-dialog-content">
        <DialogContentText id="dk-dialog-description">
          <ErrorOutlineIcon style={{ marginRight: "8px", color: "red" }} />
          {alertMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions id="dk-dialog-actions">
        <Button
          onClick={onClose}
          className={`btn-gradient ${
            typeOfProduct === "collateral" ? "btn-gradient_collateral" : ""
          } ${typeOfProduct === "token" ? "btn-gradient_token" : ""}`}
          autoFocus
        >
          {t("dialogRequest.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;

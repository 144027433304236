import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  ButtonGroup,
  Button,
  CircularProgress,
  Box,
  Pagination,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductRepository from "../../../data/repository/ProductRepository";
import { useTranslation } from "react-i18next";
import WineryOwnCollateralsComponent from "./WineryOwnCollateralsComponent";
import WineryAdquiredCollateralsComponent from "./WineryAdquiredCollateralsComponent";

const WineryCollateralsComponent = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [collateralsWinery, setCollateralsWinery] = useState([]);
  const productRepository = new ProductRepository();
  const [loading, setLoading] = useState(true);
  /* const [anchorEl, setAnchorEl] = useState(null); */
  /* const [selectedCollateralId, setSelectedCollateralId] = useState(null); */
  /* const collateralRepository = new CollateralRepository(); */
  const [exchangeMode, setExchangeMode] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);

  const fetchWineryCollaterals = async (page) => {
    setLoading(true);
    try {
      const collaterals = await productRepository.findWineryCollaterals(
        page ?? pageSelected
      );
      setRows(collaterals.rows);
      setNumberOfPages(
        Math.ceil(
          Number(collaterals.pagination?.count / collaterals.pagination?.limit)
        )
      );
    } catch (error) {
      console.error("Error al obtener colaterales del usuario:", error);
    }
    setLoading(false);
  };

  const fetchCollateralsWinery = async (page) => {
    setLoading(true);
    try {
      const collaterals = await productRepository.getCollateralsCellars(
        page ?? pageSelected
      );
      setCollateralsWinery(collaterals.rows);
      setNumberOfPages(
        Math.ceil(
          Number(collaterals.pagination?.count / collaterals.pagination?.limit)
        )
      );
    } catch (error) {
      console.error("Error al obtener colaterales de la bodega:", error);
    }
    setLoading(false);
  };

  const clickPaginationColaterals = async (event, page) => {
    if (!loading) {
      fetchWineryCollaterals(page).then(() => setPageSelected(page));
    }
  };

  const clickPaginationWineryColaterals = async (event, page) => {
    if (!loading) {
      fetchCollateralsWinery(page).then(() => setPageSelected(page));
    }
  };

  useEffect(() => {
    fetchWineryCollaterals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageSelected(1);
  }, []);

  const handleTabs = (value) => {
    setExchangeMode(value);
    if (value === 0) {
      clickPaginationColaterals(null, 1);
    }
    if (value === 1) {
      clickPaginationWineryColaterals(null, 1);
    }
  };
  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("myNFTs.misColaterales")}
      </Typography>

      {loading ? (
        <Grid item xs={12} sm={9}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress
              size={80}
              style={{
                color: "#F36E1E",
              }}
            />
          </div>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sm={9}>
            <Grid item xs={12} sx={{ marginTop: { xs: "0", sm: "4rem" } }}>
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
              >
                <Button
                  onClick={() => {
                    handleTabs(0);
                  }}
                  style={
                    exchangeMode === 0
                      ? {
                          backgroundColor: "#F36E1E",
                        }
                      : { background: "#878787" }
                  }
                >
                  {t("myNFTs.myCollaterals")}
                </Button>
                <Button
                  onClick={() => {
                    handleTabs(1);
                  }}
                  style={
                    exchangeMode === 1
                      ? {
                          backgroundColor: "#F36E1E",
                        }
                      : { background: "#878787" }
                  }
                >
                  {t("myNFTs.purchasedCollaterals")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>

          {exchangeMode === 0 ? (
            <>
              <WineryOwnCollateralsComponent rows={rows} />
              <Box display="flex" mt={2} justifyContent={"center"}>
                <Pagination
                  count={numberOfPages}
                  defaultPage={pageSelected}
                  siblingCount={2}
                  onChange={clickPaginationColaterals}
                  disabled={loading}
                />
              </Box>
            </>
          ) : (
            exchangeMode === 1 && (
              <>
                <WineryAdquiredCollateralsComponent rows={collateralsWinery} />
                <Box display="flex" mt={2} justifyContent={"center"}>
                  <Pagination
                    count={numberOfPages}
                    defaultPage={pageSelected}
                    siblingCount={2}
                    onChange={clickPaginationWineryColaterals}
                    disabled={loading}
                  />
                </Box>
              </>
            )
          )}
        </>
      )}
    </Container>
  );
};

export default WineryCollateralsComponent;

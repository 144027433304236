import DrinksifyLayout from "./presentation/layouts/DrinksifyLayout";
import HomeView from "./presentation/views/HomeView";
import AllAboutView from "./presentation/views/AllAboutView";
import BenefitsBlueView from "./presentation/views/BenefitsBlueView";
import TermsView from "./presentation/views/TermsView";
import PolicyView from "./presentation/views/PolicyView";
import CookiesView from "./presentation/views/CookiesView";
import RegisterView from "./presentation/views/RegisterView";
import RegisterCommerceView from "./presentation/views/RegisterCommerceView";
import ChangePasswordView from "./presentation/views/ChangePasswordView";
import MarketplaceView from "./presentation/views/MarketplaceView";
import MarketplaceBenefitsView from "./presentation/views/MarketplaceBenefitsView";
import DetailProductView from "./presentation/views/DetailProductView";
import CheckoutView from "./presentation/views/CheckoutView";
import WaitPaymentView from "./presentation/views/WaitPaymentView";
import UserTokensView from "./presentation/views/UserTokensView";
import UserDataView from "./presentation/views/UserDataView";
import UserBenefitsView from "./presentation/views/UserBenefitsView";
import CommerceBenefitsView from "./presentation/views/CommerceBenefitsView";
import CommerceReportsView from "./presentation/views/CommerceReportsView";
import RegisterWineryView from "./presentation/views/RegisterWineryView";
import UserPointsView from "./presentation/views/UserPointsView";
import RegisterFinancialView from "./presentation/views/RegisterFinancialView";
import FinancialCollateralsView from "./presentation/views/FinancialCollateralsView";
import WineryCollateralsView from "./presentation/views/WineryCollateralsView";

export const drinksifyPages = [
  {
    exact: true,
    path: "/",
    theme: "dark",
    neededAuth: false,
    component: HomeView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/about",
    theme: "dark",
    neededAuth: false,
    component: AllAboutView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/benefits",
    theme: "dark",
    neededAuth: false,
    component: BenefitsBlueView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/terms",
    theme: "dark",
    neededAuth: false,
    component: TermsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/policy",
    theme: "dark",
    neededAuth: false,
    component: PolicyView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/cookies",
    theme: "dark",
    neededAuth: false,
    component: CookiesView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/register-user",
    neededAuth: false,
    component: RegisterView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/register-commerce",
    neededAuth: false,
    component: RegisterCommerceView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/register-winery",
    neededAuth: false,
    component: RegisterWineryView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/register-financial",
    neededAuth: false,
    component: RegisterFinancialView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/reset_password",
    neededAuth: false,
    component: ChangePasswordView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/marketplace",
    neededAuth: false,
    component: MarketplaceView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/marketplace-benefits",
    neededAuth: false,
    component: MarketplaceBenefitsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/product/detail/:id",
    neededAuth: false,
    component: DetailProductView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/token/detail/:id",
    neededAuth: false,
    component: DetailProductView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/collateral/detail/:id",
    neededAuth: false,
    component: DetailProductView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/checkout",
    neededAuth: true,
    component: CheckoutView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/payment/wait/:internalReference",
    neededAuth: true,
    component: WaitPaymentView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/payment/wait",
    neededAuth: true,
    component: WaitPaymentView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/tokens",
    neededAuth: true,
    component: UserTokensView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/financial-collaterals",
    neededAuth: true,
    component: FinancialCollateralsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/winery-collaterals",
    neededAuth: true,
    component: WineryCollateralsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/user-data",
    neededAuth: true,
    component: UserDataView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/my-benefits",
    neededAuth: true,
    component: UserBenefitsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/benefits",
    neededAuth: true,
    component: CommerceBenefitsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/points",
    neededAuth: true,
    component: UserPointsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/reports",
    neededAuth: true,
    component: CommerceReportsView,
    layout: DrinksifyLayout,
  },
  {
    exact: true,
    path: "/profile/benefits/:benefitId",
    neededAuth: false,
    component: CommerceBenefitsView,
    layout: DrinksifyLayout,
  },
];

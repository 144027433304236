import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WhatWeDo = () => {
    const { t } = useTranslation();
    return (
        <Container id="quienes-somos" maxWidth="xl">
            <Grid container spacing={4} mt={2} mb={12} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: {xs: '16px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                        {t('about.whatWeDo.title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}}} align="center">
                        {t('about.whatWeDo.paragraph1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}}} align="center">
                        {t('about.whatWeDo.paragraph2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}}} align="center">
                        {t('about.whatWeDo.paragraph3')}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default WhatWeDo;
import React from "react";
import FinancialCollateralsComponent from "../components/profile/FinancialCollateralsComponent";

const FinancialCollateralsView = () => {
  return (
    <div>
      <FinancialCollateralsComponent />
    </div>
  );
};

export default FinancialCollateralsView;

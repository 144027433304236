import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Cookies = () => {
    const { t } = useTranslation();
    return (
        <Container id="quienes-somos" maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={12} px={10} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: {xs: '16px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies7')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies8')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle7')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies9')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle8')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies10')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle9')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies11')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.subtitle10')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies12')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.type1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.type2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies13')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('cookies.title5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies14')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies15')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies16')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies17')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies18')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies19')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies20')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('cookies.cookies21')}
                    </Typography>
                </Grid>


            </Grid>
        </Container>
    );
}

export default Cookies;
import React from "react";
import CommerceReportsComponent from "../components/profile/CommerceReportsComponent";


const CommerceReportsView = () => {

    return (
        <div>
            <CommerceReportsComponent/>
        </div>
    );
}

export default CommerceReportsView;
import React from 'react';
import RegisterForm from '../components/accounts/RegisterForm';

const RegisterView = () => {
  return (
    <div>
      <RegisterForm />
    </div>
  );
};

export default RegisterView;

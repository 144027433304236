import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import Collage from '../../assets/images/HomeBenefits.png';
import { useTranslation } from 'react-i18next';

const HomeBenefits = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    return (
        <Container maxWidth="xl">
        <Grid container spacing={4} alignItems={"center"}>
            <Grid item xs={6} sm={6}>
                <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                    <img src={Collage} alt="Collage" style={{ width: '80%' }} />
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1" className="text-gradient" sx={{ fontSize: { xs: '12px', md: '16px' }, fontWeight: 'bold', textAlign: { xs: 'center', md: 'left' } }} gutterBottom>
                    {t('home.section1.subtitle')}
                </Typography>
                <Typography variant="h3" sx={{color: 'white', fontSize: { xs: '20px', md: '40px' }, fontWeight: 'bold' }} gutterBottom>
                    {t('home.section1.title')}
                </Typography>
                <Typography variant="body1" sx={{mt: 4, color: 'white', textAlign: 'justify', textJustify: 'inter-word'}} gutterBottom>
                    {t('home.section1.p1')}
                </Typography>
                <Typography variant="body1" sx={{mt: 3, color: 'white', textAlign: 'justify', textJustify: 'inter-word'}} gutterBottom>
                    {t('home.section1.p2')}
                </Typography>
                <Box sx={{ mt: 5, display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }} onClick={ () => navigate("/benefits")}>
                    <Button variant="contained" className={'btn-gradient'}>
                        {t('home.section1.button')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
        </Container>
    );
};

export default HomeBenefits;
import api from "../api";

class ContactRepository {
    async contact(contactForm) {
        try {
            const response = await api.post('/contact', { email: contactForm.email, message: contactForm.message });
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error sending message:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }
}

export default ContactRepository;
import apiToken from "../apiToken";
import api from "../api";

class TokenRepository {
    async getTokensFiltered(params = {}) {
        try {
            const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/marketplace/tokens?${urlParams}` : '/marketplace/tokens';

            const response = await api.get(endpoint);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getTokensInBuyback(params = {}) {
        try {
            const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/buyback/tokens?${urlParams}` : '/buyback/tokens';

            const response = await apiToken.get(endpoint);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async findById(id) {
        try {
            const response = await api.get('/token/detail/'+id);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async putUpInSale(tokenId, price, startDate, endDate) {
        try {
            const response = await apiToken.post('/token/sell', {
                id: tokenId,
                price: price,
                startDate: startDate,
                endDate: endDate
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error putting up token:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async putUpInBuybackSale(tokenId) {
        try {
            const response = await apiToken.patch('/token/sell/buyback', {
                id: tokenId,
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error putting up token:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async cancelSale(tokenId) {
        try {
            const response = await apiToken.patch('/token/sell/cancel', {
                id: tokenId,
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error putting up token:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async userRequestToken(tokenId, values) {
        try {
            const response = await apiToken.post('/user/request/token/'+tokenId, {
                name: values.name,
                address: values.address,
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error putting up token:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async giftToken(tokenId, email) {
        try {
            const response = await apiToken.post('/token/gift', {
                id: tokenId,
                email: email
            });

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error gifting the token:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }
}

export default TokenRepository;
import React from "react";
import {
  Typography,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FaqsComponent = () => {
  const { t } = useTranslation();
  const items = [
    { title: t("about.faqs.item1.title"), text: t("about.faqs.item1.text") },
    { title: t("about.faqs.item2.title"), text: t("about.faqs.item2.text") },
    { title: t("about.faqs.item3.title"), text: t("about.faqs.item3.text") },
    { title: t("about.faqs.item4.title"), text: t("about.faqs.item4.text") },
    { title: t("about.faqs.item5.title"), text: t("about.faqs.item5.text") },
    { title: t("about.faqs.item6.title"), text: t("about.faqs.item6.text") },
    { title: t("about.faqs.item7.title"), text: t("about.faqs.item7.text") },
    { title: t("about.faqs.item8.title"), text: t("about.faqs.item8.text") },
    { title: t("about.faqs.item9.title"), text: t("about.faqs.item9.text") },
    { title: t("about.faqs.item10.title"), text: t("about.faqs.item10.text") },
    { title: t("about.faqs.item11.title"), text: t("about.faqs.item11.text") },
    { title: t("about.faqs.item12.title"), text: t("about.faqs.item12.text") },
  ];

  return (
    <Container id="preguntas-frecuentes" maxWidth={"xl"}>
      <Grid container mt={12} mb={12}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("about.faqs.title")}
          </Typography>
        </Grid>

        <Grid item xs={12} mt={6}>
          {items?.map((item, index) => (
            <Accordion
              key={index}
              style={{ background: "transparent", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    fontSize: { xs: "14px", sm: "19px" },
                    fontWeight: "bold",
                  }}
                >
                  {`${index + 1}. ${item.title}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ color: "white", fontSize: { xs: "14px", sm: "15px" } }}
                >
                  {item.text}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default FaqsComponent;

import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Hero from "../components/Hero";
import FooterComponent from "../components/FooterComponent";
import backgroundImage from "../../assets/images/FONDO-BENEFITS.png";
import { useTranslation } from 'react-i18next';
import BenefitsTitle from "../components/BenefitsTitle";
import CallToActionBenefits from "../components/CallToActionBenefits";

const BenefitsBlueView = () => {
    const { t } = useTranslation();

    const {hash, key} = useLocation();

    useEffect(() => {
        if (hash) {
            const targetElement = document.getElementById(hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [key, hash]);

    return (
        <div>
            <Hero
                subtitle={t("benefitsBlue.title")}
                backgroundImage={backgroundImage}
            />
            <BenefitsTitle />
            <CallToActionBenefits />
            <FooterComponent />
        </div>
    )
}

export default BenefitsBlueView;
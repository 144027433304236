import api from "../api";
class DenominationRepository {

    async getDenominationList() {
        try {
            const response = await api.get('/denomination/list');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default DenominationRepository;
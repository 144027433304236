import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductRepository from "../../../data/repository/ProductRepository";
import TokenRepository from "../../../data/repository/TokenRepository";
import SuccessDialog from "../SuccessDialog";
import ErrorDialog from "../ErrorDialog";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

const UserTokensComponent = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [rows, setRows] = useState([]);
  const productRepository = new ProductRepository();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openBuybackSaleModal, setOpenBuybackSaleModal] = useState(false);
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const tokenRepository = new TokenRepository();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(
    dayjs().add(365, "day").startOf("day")
  );
  const [email, setEmail] = useState("");
  const [tokenPoints, setTokenPoints] = useState(null);

  const fetchUserTokens = async () => {
    try {
      const tokens = await productRepository.findUserTokens();
      setRows(tokens);
    } catch (error) {
      console.error("Error al obtener tokens del usuario:", error);
    }
  };

  useEffect(() => {
    fetchUserTokens();
  }, []);

  const handleClick = (event, tokenId, points) => {
    handleClose();
    setAnchorEl(event.currentTarget);
    setSelectedTokenId(tokenId);
    setTokenPoints(points);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTokenId(null);
    setTokenPoints(null);
  };

  const handleOpenModal = (minPrice) => {
    setOpenModal({ minPrice: minPrice });
    setPrice(minPrice);
  };

  const handleCloseModal = () => {
    setEmail("");
    setPrice("");
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().add(365, "day").startOf("day"));
    setOpenModal(false);
    setOpenAddressModal(false);
    setOpenGiftModal(false);
    setOpenBuybackSaleModal(false);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleSell = async () => {
    if (selectedTokenId && price && startDate && endDate) {
      const result = await tokenRepository.putUpInSale(
        selectedTokenId,
        price,
        `${startDate.$y}-${startDate.$M + 1}-${startDate.$D}`,
        `${endDate.$y}-${endDate.$M + 1}-${endDate.$D}`
      );

      if (result.success) {
        setSuccess(result.data.message);
        fetchUserTokens();
        handleCloseModal();
        handleClose();
      } else {
        setError(
          result.message === "sale.error.00001" ? (
            t("myNFTs.error1")
          ) : result.message === "sale.error.00002" ? (
            t("myNFTs.error2")
          ) : result.message === "sale.error.00003" ? (
            <Trans
              i18nKey="myNFTs.error3"
              components={[
                <Link href="/profile/user-data" style={{ margin: "1px" }} />,
              ]}
            />
          ) : result.message === "sale.error.00004" ? (
            t("myNFTs.error4")
          ) : result.message === "sale.error.00005" ? (
            t("myNFTs.error5")
          ) : result.message === "sale.error.00006" ? (
            t("myNFTs.error6")
          ) : result.message === "sale.error.00007" ? (
            t("myNFTs.error7")
          ) : result.message === "sale.error.00008" ? (
            t("myNFTs.error8")
          ) : (
            "Error: " + result.message
          )
        );
      }
    }
  };

  const handleBuybackSell = async () => {
    if (selectedTokenId) {
      const result = await tokenRepository.putUpInBuybackSale(selectedTokenId);

      if (result.success) {
        setSuccess(result.data.message);
        fetchUserTokens();
        handleCloseModal();
        handleClose();
      } else {
        setError(
          result.message === "sale.error.00001" ? (
            t("myNFTs.error1")
          ) : result.message === "sale.error.00002" ? (
            t("myNFTs.error2")
          ) : result.message === "sale.error.00003" ? (
            <Trans
              i18nKey="myNFTs.error3"
              components={[
                <Link href="/profile/user-data" style={{ margin: "1px" }} />,
              ]}
            />
          ) : result.message === "sale.error.00004" ? (
            t("myNFTs.error4")
          ) : result.message === "sale.error.00005" ? (
            t("myNFTs.error5")
          ) : result.message === "sale.error.00006" ? (
            t("myNFTs.error6")
          ) : result.message === "sale.error.00007" ? (
            t("myNFTs.error7")
          ) : result.message === "sale.error.00008" ? (
            t("myNFTs.error8")
          ) : (
            "Error: " + result.message
          )
        );
      }
    }
  };

  const handleGift = async () => {
    if (selectedTokenId && email) {
      const result = await tokenRepository.giftToken(selectedTokenId, email);

      if (result.success) {
        setSuccess(t("myNFTs.gifts.success"));
        fetchUserTokens();
      } else {
        setError(
          result.message === "gift.error.00001"
            ? t("myNFTs.gifts.error1")
            : result.message === "gift.error.00002"
            ? t("myNFTs.gifts.error2")
            : result.message === "gift.error.00003"
            ? t("myNFTs.gifts.error3")
            : result.message === "gift.error.00004"
            ? t("myNFTs.gifts.error4")
            : result.message === "gift.error.00005"
            ? t("myNFTs.gifts.error5")
            : result.message === "gift.error.00006"
            ? t("myNFTs.gifts.error6")
            : "Error: " + result.message
        );
      }

      handleCloseModal();
      handleClose();
    }
  };

  const handleCancelSale = async () => {
    if (selectedTokenId) {
      const result = await tokenRepository.cancelSale(selectedTokenId);

      if (result.success) {
        setSuccess(result.data.message);
        fetchUserTokens();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : "Error: " + result.message
        );
      }

      handleClose();
    }
  };

  const handleRequestNFT = async () => {
    if (selectedTokenId) {
      const result = await tokenRepository.userRequestToken(selectedTokenId, {
        name: name,
        address: address,
      });

      if (result.success) {
        setSuccess(result.data.message);
        fetchUserTokens();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : "Error: " + result.message
        );
      }
      setName("");
      setAddress("");
      handleCloseModal();
      handleClose();
    }
  };

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("myNFTs.misWineNFTs")}
      </Typography>

      <TableContainer component={Paper} style={{ marginTop: "3rem" }}>
        <Table aria-label="tabla de winenfts">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.bodega")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.vino")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.id")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.estado")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.fechaDeEntrega")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.cellarName}</TableCell>
                <TableCell>{row.tokenName}</TableCell>
                <TableCell>{row.tokenNumber}</TableCell>
                <TableCell>
                  {row.inSale ? (
                    <span style={{ color: "green" }}>
                      {t("myNFTs.myNFTs.enVenta")}
                    </span>
                  ) : row.status === "pending" ? (
                    <span style={{ color: "orange" }}>
                      {t("myNFTs.myNFTs.required")}
                    </span>
                  ) : row.status === "sent" ? (
                    <span style={{ color: "green" }}>
                      {t("myNFTs.myNFTs.sent")}
                    </span>
                  ) : row.status === "finished" ? (
                    <span style={{ color: "green" }}>
                      {t("myNFTs.myNFTs.finished")}
                    </span>
                  ) : (
                    row.status === null && "-"
                  )}
                </TableCell>
                <TableCell>{row.minRequestDate}</TableCell>
                <TableCell>
                  {!row.isRequested ? (
                    <>
                      <a
                        style={{
                          color: "#F36E1E",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClick(event, row.id, row.points);
                        }}
                      >
                        {t("myNFTs.acciones")}
                      </a>
                      <Menu
                        anchorEl={anchorEl}
                        open={selectedTokenId === row.id}
                        onClose={handleClose}
                      >
                        {!row.inSale ? (
                          <>
                            {!row.isRequested ? (
                              <>
                                <MenuItem
                                  key="sell"
                                  onClick={() =>
                                    handleOpenModal(row.product?.minPrice)
                                  }
                                >
                                  {t("myNFTs.confirmarVenta")}
                                </MenuItem>
                                <MenuItem
                                  key="gift"
                                  onClick={() => setOpenGiftModal(true)}
                                >
                                  {t("myNFTs.gift")}
                                </MenuItem>
                                <MenuItem
                                  key="requestNFT"
                                  onClick={() => setOpenAddressModal(true)}
                                >
                                  {t("myNFTs.solicitarNFT")}
                                </MenuItem>
                                <MenuItem
                                  key="buyback"
                                  onClick={() => setOpenBuybackSaleModal(true)}
                                >
                                  {t("myNFTs.buyback")}
                                </MenuItem>
                              </>
                            ) : null}
                            <MenuItem
                              key="mybenefits"
                              onClick={() =>
                                navigate(
                                  `/marketplace-benefits?product=${row.product.id}`,
                                  {
                                    state: {
                                      tokenId: row.id,
                                      tokenName: row.tokenName,
                                      productId: row.product.id,
                                    },
                                  }
                                )
                              }
                            >
                              {t("marketplaceBenefits.box1")}
                            </MenuItem>
                            <MenuItem
                              key="info"
                              onClick={() =>
                                window.open(
                                  `${API_BASE_URL}certification/token/${row.tokenNumber}`,
                                  "_blank"
                                )
                              }
                            >
                              {t("myNFTs.wineNftInfo")}
                            </MenuItem>
                          </>
                        ) : (
                          <MenuItem key="sell" onClick={handleCancelSale}>
                            {t("myNFTs.cancelarVenta")}
                          </MenuItem>
                        )}
                      </Menu>
                    </>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.ponerEnVentaDialog")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1" style={{ marginBottom: "0.25rem" }}>
            {t("myNFTs.ingresaPrecioVenta")}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "0.25rem" }}>
            {openModal.minPrice
              ? `${t("myNFTs.minPrice")} $${openModal.minPrice}`
              : t("myNFTs.minPriceError")}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            type="number"
            fullWidth
            value={price}
            onChange={handlePriceChange}
            placeholder={t("myNFTs.precioPlaceholder")}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="body1"
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              {t("myNFTs.startP2PDate")}
            </Typography>
            <DatePicker
              label={t("myNFTs.datepickerStart")}
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              disablePast
              sx={{ width: "100%" }}
              maxDate={endDate}
            />
            <Typography
              variant="body1"
              style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            >
              {t("myNFTs.endP2PDate")}
            </Typography>
            <DatePicker
              label={t("myNFTs.datepickerEnd")}
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              disablePast
              sx={{ width: "100%" }}
              minDate={startDate}
            />
          </LocalizationProvider>
          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {t("myNFTs.sellAlert")}
          </Typography>
          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {t("myNFTs.sellAlertPoints", { points: tokenPoints })}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.cancelar")}
          </Button>
          <Button onClick={handleSell} className={"btn-gradient"}>
            {t("myNFTs.confirmarVenta")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openGiftModal} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.giveNft")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            {t("myNFTs.emailGiveNft")}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            placeholder={t("myNFTs.email")}
          />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.cancelar")}
          </Button>
          <Button onClick={handleGift} className={"btn-gradient"}>
            {t("myNFTs.send")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddressModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("myNFTs.claimNftDialog")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{t("myNFTs.claimNftTitle1")}</Typography>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            value={name}
            onChange={handleNameChange}
            placeholder={t("myNFTs.claimNftPlaceholder1")}
          />
          <Typography variant="body1">{t("myNFTs.claimNftTitle2")}</Typography>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            value={address}
            onChange={handleAddressChange}
            placeholder={t("myNFTs.claimNftPlaceholder2")}
          />
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.cancelar")}
          </Button>
          <Button onClick={handleRequestNFT} className={"btn-gradient"}>
            {t("myNFTs.solicitarNFT")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openBuybackSaleModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{t("myNFTs.buybackSaleDialog")}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {t("myNFTs.buybackSaleTitle")}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.cancelar")}
          </Button>
          <Button onClick={handleBuybackSell} className={"btn-gradient"}>
            {t("dialogRequest.accept")}
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessDialog
        open={!!success}
        onClose={() => setSuccess(null)}
        alertTitle={t("myNFTs.gestionDeTokens")}
        alertMessage={success}
        dark={false}
      />

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("myNFTs.gestionDeTokens")}
        alertMessage={error}
        dark={false}
      />
    </Container>
  );
};

export default UserTokensComponent;

import React from "react";
import ChangePasswordForm from "../components/accounts/ChangePasswordForm";


const ChangePasswordView = () => {

    return (
        <div>
            <ChangePasswordForm />
        </div>
    );
}

export default ChangePasswordView;

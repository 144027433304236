import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import HowItWorks1 from "../../assets/images/how-it-works-1.png";
import HowItWorks2 from "../../assets/images/how-it-works-2.png";
import HowItWorks3 from "../../assets/images/how-it-works-3.png";
import HowItWorks4 from "../../assets/images/how-it-works-4.png";
import HowItWorks5 from "../../assets/images/how-it-works-5.png";
import { useTranslation } from "react-i18next";

const about = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const images = [
    {
      src: HowItWorks1,
      title: t("about.howItWorks.step1.title"),
      paragraph: t("about.howItWorks.step1.paragraph"),
    },
    {
      src: HowItWorks2,
      title: t("about.howItWorks.step2.title"),
      paragraph: t("about.howItWorks.step2.paragraph"),
    },
    {
      src: HowItWorks3,
      title: t("about.howItWorks.step3.title"),
      paragraph: t("about.howItWorks.step3.paragraph"),
    },
    {
      src: HowItWorks4,
      title: t("about.howItWorks.step4.title"),
      paragraph: t("about.howItWorks.step4.paragraph"),
    },
    {
      src: HowItWorks5,
      title: t("about.howItWorks.step5.title"),
      paragraph: t("about.howItWorks.step5.paragraph"),
    },
  ];

  return (
    <Container id="como-funciona" maxWidth="xl" mt={15} mb={15}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("about.howItWorks.title")}
          </Typography>
        </Grid>

        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography
            variant="body1"
            sx={{ color: "white", fontSize: { xs: "14px", sm: "20px" } }}
            align="center"
          >
            {t("about.howItWorks.subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={2} />

        {images?.map((image, index) => (
          <Grid
            item
            xs={8}
            sm={2}
            key={index}
            sx={{ minHeight: "348px", alignSelf: "stretch" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={image.src}
                alt={`Icon ${index}`}
                style={{ maxWidth: "105px", objectFit: "cover" }}
              />
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                mt={3}
                style={{ color: "white", fontWeight: "bold" }}
              >
                {image.title}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                mt={2}
                style={{ color: "white" }}
              >
                {image.paragraph}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/*<Grid container justifyContent="center" mt={8}>
                <Button className={'btn-gradient'} style={{color: 'white', padding: '10px 40px !important'}}>
                    {t('about.howItWorks.button')}
                </Button>
            </Grid>*/}
    </Container>
  );
};

export default about;

import React from "react";
import RegisterCommerceForm from "../components/accounts/RegisterCommerceForm";


const RegisterCommerceView = () => {

    return (
        <div>
            <RegisterCommerceForm/>
        </div>
    );
}

export default RegisterCommerceView;

import api from "../api";
class CategoriesRepository {
    
    async getCategories(language) {
        try {
            const endpoint = '/category/list';
            const response = await api.get(endpoint, {headers:{
                "x-locale": language
              }});
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

export default CategoriesRepository;
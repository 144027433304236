import apiToken from "../apiToken";

class ReportsRepository {
  async getBenefitsReport(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `/panel/benefits/report?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitsExcel(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(
        `/panel/benefits/report?excel=true&${urlParams}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTokensReport(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(`/panel/tokens/report?${urlParams}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getTokensExcel(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(
        `/panel/tokens/report?excel=true&${urlParams}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSalesReport(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(`/panel/orders?${urlParams}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSalesExcel(page) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(`/panel/orders?excel=true&${urlParams}`, {
        responseType: "blob",
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitPointsReport(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `/panel/benefits/points?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitPointsExcel(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(
        `/panel/benefits/points?excel=true&${urlParams}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitExchangedReport(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `/panel/benefits/exchanged?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitExchangedExcel(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(
        `/panel/benefits/exchanged?excel=true&${urlParams}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitTransactionsReport(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = await apiToken.get(
        `/panel/benefits/transactions?${urlParams}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getBenefitTransactionsExcel(page, filters = []) {
    try {
      const allParams = {
        page: page,
      };

      const filteredParams = Object.fromEntries(
        ["page"]
          .filter((key) => key in allParams) // line can be removed to make it inclusive
          ?.map((key) => [key, allParams[key]])
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const response = apiToken.get(
        `/panel/benefits/transactions?excel=true&${urlParams}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTokenShipmentStatus() {
    try {
      const response = await apiToken.get(`/panel/shipment/status`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateTokenShipment(id, status, trackingLink) {
    try {
      const response = await apiToken.patch(`/panel/shipment/${id}`, {
        status,
        trackingLink,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default ReportsRepository;

import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Policy = () => {
    const { t } = useTranslation();
    return (
        <Container id="quienes-somos" maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={12} px={10} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: {xs: '16px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy7')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy8')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy9')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy10')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy11')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy12')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy13')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy14')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy15')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy16')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy17')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy18')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle7')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy19')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.title5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle8')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy20')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy21')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy22')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" sx={{color: 'white', fontSize: {xs: '13px', sm: '20px'}, fontWeight: 'bold'}} align="center">
                        {t('policy.subtitle9')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('policy.policy23')}
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    );
}

export default Policy;
import React from "react";
import { useParams } from 'react-router-dom';
import CommerceBenefitsDetailComponent from "../components/profile/CommerceBenefitsDetailComponent";
import UserBenefitsComponent from "../components/profile/UserBenefitsComponent";


const CommerceBenefitsView = () => {

    const { benefitId } = useParams();

    return (
        <div>
            { benefitId ?
                <CommerceBenefitsDetailComponent benefitId = { benefitId }/>
                :
                <UserBenefitsComponent />
            }
        </div>
    );
}

export default CommerceBenefitsView;
import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Terms = () => {
    const { t } = useTranslation();
    return (
        <Container id="quienes-somos" maxWidth="xl">
            <Grid container spacing={2} mt={2} mb={12} px={10} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: {xs: '16px', sm: '32px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title1')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px',  color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms7')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms8')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms9')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms10')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms11')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms12')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title2')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms13')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title3')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms14')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms15')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms16')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms17')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms18')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms19')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title4')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms20')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title5')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms21')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms22')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms23')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginLeft: '32px', color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms24')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" sx={{color: 'white', fontSize: {xs: '14px', sm: '24px'}, fontWeight: 'bold'}} align="center">
                        {t('terms.title6')}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" sx={{color: 'white', fontSize: {xs: '12px', sm: '16px'}, textAlign: 'justify', textJustify: 'inter-word'}} >
                        {t('terms.terms25')}
                    </Typography>
                </Grid>

            </Grid>
        </Container>
    );
}

export default Terms;
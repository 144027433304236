import React, {useState} from 'react';
import { Button, Typography, Box, Container, Grid, TextField } from '@mui/material';
import SubscriberRepository from "../../data/repository/SubscriberRepository";
import Subscriber from '../../core/entity/Subscriber';
import ErrorDialog from "./ErrorDialog";
import SuccessDialog from "./SuccessDialog";
import { useTranslation } from 'react-i18next';

const CallToActionSubscribe = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const repository = new SubscriberRepository();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const subscriber = new Subscriber(email);
            const response = await repository.subscribe(subscriber);

            if (response.success) {
                setEmail('');
                setError(null);
                setSuccess(response.data.message);
            } else {
                setError(response.message);
            }
        } catch (error) {
            setError(error.message);
        }

    };

    return (
        <Container maxWidth={'xl'}>
            <Box
                mt={15} mb={15}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={4}
                style={{
                    background: 'linear-gradient(90deg, transparent, #F47F26, transparent)'
                }}
            >
                <Grid container xs={1}></Grid>
                <Grid container xs={10} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h3" sx={{color: 'white', fontSize: { xs: '16px', md: '24px' }, textAlign: { xs: 'center', sm: 'left' }}}>
                            {t('home.callToAction2.title')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} flexDirection={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="center">
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    variant="outlined"
                                    placeholder={t('home.callToAction2.placeholder')}
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: '55px',
                                        border: 'none',
                                    }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '55px',
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="outlined"
                                    style={{
                                        color: 'white',
                                        borderColor: 'white',
                                        backgroundColor: 'transparent',
                                        padding: '10px 30px',
                                        borderRadius: '55px',
                                    }}>
                                    {t('home.callToAction2.button')}
                                </Button>
                            </Grid>
                        </Grid>
                        </form>
                    </Grid>
                </Grid>
                <Grid container xs={1}></Grid>
            </Box>

            <ErrorDialog
                open={!!error}
                onClose={() => setError(null)}
                alertTitle={'Error al subscribirse'}
                alertMessage={error}
                dark={true}
            />

            <SuccessDialog
                open={!!success}
                onClose={() => setSuccess(null)}
                alertTitle={'¡Felicidades!'}
                alertMessage={success}
                dark={true}
            />
        </Container>
    );
}

export default CallToActionSubscribe;
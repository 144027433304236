import React, { useState, useEffect } from "react";
import { Typography, Button, Container, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import BenefitsRepository from "../../../data/repository/BenefitsRepository";
import LoginModal from "../LoginModal";

const CommerceBenefitsDetailComponent = (props) => {
  const [t, i18n] = useTranslation();

  const tableKeys = [
    "tableKey1",
    "tableKey3",
    "tableKey4",
    "tableKey5",
    "tableKey6",
    "tableKey7",
  ];

  const { isAuthenticated } = useAuth();

  const [benefit, setBenefit] = useState(null);
  const [isBenefitLoading, setIsBenefitLoading] = useState(false);
  const [benefitError, setBenefitError] = useState(false);
  const [isBenefitSending, setIsBenefitSending] = useState(false);
  const [benefitSendingError, setBenefitSendingError] = useState(false);
  const [amount, setAmount] = useState(0);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const benefitsRepository = new BenefitsRepository();

  const fetchTokenBenefit = async (token) => {
    setIsBenefitLoading(true);
    try {
      const benefit = await benefitsRepository.findTokenBenefitById(token);
      setBenefit(benefit);
    } catch (error) {
      console.error("Error:", error);
      setBenefitError(error);
    }
    setIsBenefitLoading(false);
  };

  const handleAccept = async (token, quantity, uses) => {
    if (quantity < 1 || quantity > uses) {
      return null;
    } else {
      setBenefitSendingError(false);
      setIsBenefitSending(true);
      try {
        await benefitsRepository.validateBenefit(
          token,
          Number(quantity).toFixed(0)
        );
        fetchTokenBenefit(props.benefitId);
        setBenefitSendingError({
          status: 1,
          message: t("commerceBenefits.accepted"),
        });
      } catch (error) {
        console.error("Error:", error);
        setBenefitSendingError({
          status: 0,
          message: t("commerceBenefits.rejected"),
        });
      }
      setIsBenefitSending(false);
    }
  };

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = () => {
    setLoginModalOpen(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchTokenBenefit(props.benefitId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleLoginClick();
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: { xs: "3rem", sm: "5rem" },
        justifyContent: "start",
        alignItems: "center",
        paddingBottom: "1rem",
      }}
    >
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("commerceBenefits.title")}
      </Typography>
      <Box
        sx={{
          width: "calc(100% - 32px)",
          maxWidth: "796px",
          display: "flex",
          flexDirection: "column",
          gap: "0rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!benefit || !benefit?.remaining ? (
          <Typography
            variant="p"
            sx={{
              width: "100%",
              textAlign: "center",
              color: "red",
              fontSize: { xs: "12px", sm: "16px" },
            }}
          >
            {!benefit?.remaining
              ? t("commerceBenefits.usedBenefit")
              : isBenefitLoading
              ? t("commerceBenefits.loadingBenefit")
              : t("commerceBenefits.emptyBenefit")}
          </Typography>
        ) : (
          <>
            {tableKeys?.map((key, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "calc(100% - 32px)",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: "1rem",
                    gap: { xs: "0.5rem", sm: "1rem" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: index ? "1px solid #878787" : "0px",
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{
                      color: "#F36E1E",
                      padding: "0rem",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {t(`commerceBenefits.${key}`)}
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{
                      color: "#878787",
                      padding: "0rem",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {key === "tableKey1"
                      ? benefit?.user?.name + " " + benefit?.user?.surname
                      : key === "tableKey3"
                      ? benefit.cellar.name
                      : key === "tableKey4"
                      ? benefit.token
                      : key === "tableKey5"
                      ? benefit.benefit.name
                      : key === "tableKey6"
                      ? new Date(
                          benefit?.benefit?.expiration?.date
                        ).toLocaleDateString("es-Es")
                      : key === "tableKey7"
                      ? benefit.remaining
                      : null}
                  </Typography>
                </Box>
              );
            })}
            {benefit.remaining ? (
              <>
                <Box
                  sx={{
                    width: "calc(100% - 32px)",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    padding: "1rem",
                    gap: { xs: "0.5rem", sm: "1rem" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "1px solid #878787",
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{
                      color: "#F36E1E",
                      padding: "0rem",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {t("commerceBenefits.amountToActivate")}
                  </Typography>
                  <TextField
                    id="standard-basic"
                    required
                    size="small"
                    variant="standard"
                    type="number"
                    step="1"
                    value={amount}
                    onChange={(event) =>
                      setAmount(Number(event.target.value).toFixed(0))
                    }
                    sx={{ maxWidth: "48px" }}
                  />
                </Box>
                {isBenefitSending ? null : (
                  <Box
                    sx={{
                      width: "calc(100% - 32px)",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginTop: "rem",
                    }}
                  >
                    <Button
                      disabled={
                        amount > 0 && amount <= benefit?.remaining
                          ? false
                          : true
                      }
                      variant="contained"
                      onClick={() =>
                        handleAccept(
                          benefit.benefitToken,
                          amount,
                          benefit?.remaining
                        )
                      }
                      className={"btn-gradient"}
                      type="submit"
                    >
                      {t("commerceBenefits.activate")}
                    </Button>
                  </Box>
                )}
              </>
            ) : null}
            {!benefitSendingError ? null : (
              <Typography
                variant="h3"
                style={{
                  color: benefitSendingError.status ? "green" : "red",
                  padding: "0rem",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "1rem",
                }}
              >
                {benefitSendingError.message}
              </Typography>
            )}
          </>
        )}
      </Box>
      {!isAuthenticated ? (
        <LoginModal
          open={loginModalOpen}
          onClose={handleLoginClose}
          theme={"white"}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default CommerceBenefitsDetailComponent;

const MIN_PASSWORD_LENGTH = 8;

export default class ChangePassword {
    constructor({ password, confirmPassword }) {
        this.validatePassword({ password, confirmPassword });
        this.password = password;
    }

    validatePassword({ password, confirmPassword }) {
        let errors = {};

        const error = this.isValidPassword(password, confirmPassword);

        if (error) {
            errors.password = error;
        }

        return errors;
    }

    isValidPassword(password, confirmPassword) {
        if (password.length < MIN_PASSWORD_LENGTH) {
            return `La contraseña debe contener al menos ${MIN_PASSWORD_LENGTH} caracteres`;
        }

        const hasNumber = /\d/;
        const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (!hasNumber.test(password)) {
            return 'La contraseña debe contener al menos un número';
        }

        if (!hasSymbol.test(password)) {
            return 'La contraseña debe contener al menos un símbolo';
        }

        if (password !== confirmPassword) {
            return 'Las contraseñas no coinciden';
        }

        return null;
    }

}


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";
import ProductRepository from "../../data/repository/ProductRepository";
import { useTranslation } from "react-i18next";
import MarketplaceCard from "./marketplace/MarketplaceCard";

const HomeMarketplace = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const repository = new ProductRepository();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await repository.getProductsFiltered({ limit: 4 });
        setProducts(response?.map((product) => product));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  if (0 >= products.length) {
    return null;
  }

  const handleCardClick = (productId) => {
    navigate(`/product/detail/${productId}`);
  };

  return (
    <Container maxWidth={"xl"}>
      <Box textAlign="center">
        <Typography
          variant="h2"
          sx={{
            color: "white",
            fontSize: { xs: "25px", sm: "35px" },
            fontWeight: "bold",
          }}
          gutterBottom
        >
          {t("home.marketplace.title")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "white", fontSize: { xs: "14px", sm: "20px" } }}
          paragraph
        >
          {t("home.marketplace.subtitle")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {products?.map((product, index) => {
          if (index > 3) {
            return null;
          } else {
            return (
              <MarketplaceCard
                key={index}
                product={product}
                exchangeMode={false}
                handleCardClick={handleCardClick}
                dark={true}
              />
            );
          }
        })}
      </Box>

      <Grid container justifyContent="center" mt={8}>
        <Button
          className={"btn-gradient"}
          style={{ color: "white", padding: "10px 40px !important" }}
          onClick={() => navigate("/marketplace")}
        >
          {t("home.marketplace.button")}
        </Button>
      </Grid>
    </Container>
  );
};

export default HomeMarketplace;

export default class ContactForm {
    constructor(email, message) {
        this.isValidEmail(email);
        this.email = email;

        this.isValidMessage(message);
        this.message = message;
    }

    isValidEmail(email){
        const regex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!email || email.trim() === '') {
            throw new Error('Email no puede estar vacío.');
        }

        if (!regex.test(email)) {
            throw new Error("Invalid email: " + email);
        };
    }

    isValidMessage(message) {
        if (5 >= message.length) {
            throw new Error('El mensaje es demasiado corto')
        }
    }
}
import apiToken from "../apiToken";
class CartRepository {
    async getList(params = {}) {
        try {
            const response = await apiToken.get('cart/list?conversion=true');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async addProduct(productId, tokenId) {
        try {
            const response = await apiToken.post('/cart/add', tokenId ? 
                { productId: productId, tokenId: tokenId } :
                { productId: productId }
            );
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error subscribing the user:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async subtractProduct(productId, tokenId) {
        try {
            const response = await apiToken.post('/cart/subtract', tokenId ? 
                { productId: productId, tokenId: tokenId } :
                { productId: productId }
            );
            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error subscribing the user:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }

    async empty() {
        try {
            const response = await apiToken.delete('/cart/empty');

            return { success: true, data: response.data };
        } catch (error) {
            console.error("Error subscribing the user:", error);
            return { success: false, message: error.response ? error.response.data.message : error.message };
        }
    }
}

export default CartRepository;
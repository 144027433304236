import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container, CircularProgress } from '@mui/material';
import CheckoutRepository from "../../../data/repository/CheckoutRepository";
import { useParams } from 'react-router-dom';

const WaitPaymentComponent = () => {
    
    const { t } = useTranslation();

    const [paymentStatus, setPaymentStatus] = useState(null);
    const repository = new CheckoutRepository();
    const { internalReference } = useParams();

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const response = await repository.checkOrder(internalReference);

                if (response.payment === 'success') {
                    setPaymentStatus("confirmed");
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error('Error al verificar el estado del pago:', error);
            }
        };

        const intervalId = setInterval(checkPaymentStatus, 5000);
        return () => clearInterval(intervalId);
    }, [internalReference]);

    if (!paymentStatus && internalReference) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
            <CircularProgress size={80} style={{ color: '#F36E1E' }} />
        </div>;
    }


    return (
        <Container maxWidth="xl">
            <Typography variant="h1" style={{ color: '#F36E1E', padding: '10px', textAlign: 'center', fontSize: '35px', fontWeight: 'bold', marginTop: '5rem' }}>
                {t('waitPayment.success')}
            </Typography>
            <Typography variant={'body1'} style={{ textAlign: 'center' }}>
                {t('waitPayment.received')}
            </Typography>
        </Container>
    );
}

export default WaitPaymentComponent;
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext";

const Logout = () => {
    const { setIsAuthenticated, setRoles } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.removeItem('jwt');
        setIsAuthenticated(false);
        setRoles([]);
        navigate('/');
    }, [setIsAuthenticated, navigate]);

    return null;
};

export default Logout;
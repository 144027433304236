import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import IconExistenceProof from "../../assets/images/icon-existence-proof.png";
import IconSecureTransaction from "../../assets/images/icon-secure-transactions.png";
import IconLiquidityDisintermediation from "../../assets/images/icon-liquidity-disintermediation.png";
import IconInnovation from "../../assets/images/icon-innovation.png";
import { useTranslation } from "react-i18next";

const HomeInfo2 = () => {
  const { t } = useTranslation();
  const images = [
    {
      src: IconExistenceProof,
      title: t("home.section3.column1.title"),
      paragraph: t("home.section3.column1.p"),
    },
    {
      src: IconSecureTransaction,
      title: t("home.section3.column2.title"),
      paragraph: t("home.section3.column2.p"),
    },
    {
      src: IconLiquidityDisintermediation,
      title: t("home.section3.column3.title"),
      paragraph: t("home.section3.column3.p"),
    },
    {
      src: IconInnovation,
      title: t("home.section3.column4.title"),
      paragraph: t("home.section3.column4.p"),
    },
  ];

  return (
    <Container maxWidth="xl" mt={15} mb={15}>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("home.section3.title")}
          </Typography>
        </Grid>

        <Grid item xs={3} sx={{ display: { xs: "none", sm: "block" } }} />
        <Grid item sm={6}>
          <Typography
            variant="body1"
            style={{ color: "white", fontSize: { xs: "14px", sm: "20px" } }}
            align="center"
          >
            {t("home.section3.subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: { xs: "none", sm: "block" } }} />

        {images?.map((image, index) => (
          <Grid
            item
            xs={12}
            sm={3}
            key={index}
            sx={{ minHeight: "348px", alignSelf: "stretch" }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={image.src}
                alt={`Icon ${index}`}
                style={{ maxWidth: "105px", objectFit: "cover" }}
              />
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                mt={3}
                style={{ color: "white", fontWeight: "bold" }}
              >
                {image.title}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                mt={2}
                style={{ color: "white" }}
              >
                {image.paragraph}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomeInfo2;

import React from 'react';
import { Box, Typography, Grid, IconButton, Container } from '@mui/material';
import IconWhatsApp from '../../assets/images/whatsapp.png';
import IconInstagram from '../../assets/images/instagram.png';
import IconFacebook from '../../assets/images/facebook.png';

const Hero = ({title, subtitle, backgroundImage}) => {
    return (
        <Box
            sx={{
                position: "relative",
                height: "100vh",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Container maxWidth="xl" textAlign="center"  sx={{ paddingTop: "1rem" }}>
                <Box container justifyContent="center" alignItems="center">
                    {title && (
                    <Typography variant="body1" textAlign="center" className="text-gradient" sx={{ fontSize: { xs: '12px', sm: '16px' }, fontWeight: 'bold' }} gutterBottom>
                        {title}
                    </Typography>
                    )}
                    <Typography variant="h1" textAlign="center" sx={{
                        fontWeight: 'bold',
                        fontSize: { xs: '20px', sm: '40px' }
                    }}  gutterBottom>
                        {subtitle}
                    </Typography>
                </Box>
                <Box sx={{
                    position: 'absolute',
                    top: '40%',
                    right: '2%',
                    transform: 'translateY(-100%)',
                    display: { xs: 'none', sm: 'flex' },
                    flexDirection: 'column'
                }}>
                    <IconButton color="inherit" target="_blank" aria-label="Facebook" href="https://www.facebook.com/Drinksify.ok">
                        <img src={IconFacebook} alt={'Facebook'} style={{ width: '40px' }}/>
                    </IconButton>
                    <IconButton color="inherit" target="_blank" aria-label="Instagram" href="https://www.instagram.com/drinksifyok/">
                        <img src={IconInstagram} alt={'Instagram'} style={{ width: '40px' }}/>
                    </IconButton>
                    {/*<IconButton color="inherit" target="_blank" aria-label="WhatsApp" href="https://www.linkedin.com/company/drinksify">
                        <img src={IconWhatsApp} alt={'WhatsApp'} style={{ width: '40px' }}/>
                    </IconButton>*/}
                </Box>
            </Container>
        </Box>
    );
};

export default Hero;
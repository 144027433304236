import React from "react";
import RegisterWineryForm from "../components/accounts/RegisterWineryForm";


const RegisterWineryView = () => {

    return (
        <div>
            <RegisterWineryForm/>
        </div>
    );
}

export default RegisterWineryView;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {
  ListItemText,
  Drawer,
  List,
  ListItem,
  Box,
  Typography
} from '@mui/material';
import Logo from '../../assets/images/LOGO DRINKSIFY.png';
import LogoDark from '../../assets/images/LOGO-DRINKSIFY-DARK.png';
import Cart from '../../assets/images/Cart.png';
import CartDark from '../../assets/images/cart-dark.png';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LoginModal from './LoginModal';
import { useAuth } from '../contexts/AuthContext';
import MyAccountMenuComponent from './MyAccountMenuComponent';
import { useCart } from '../contexts/CartContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CartIconComponent from './CartIconComponent';

const Navbar = ({ theme }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const isDarkTheme = theme === 'dark';
  const textColor = isDarkTheme ? 'white' : '#160F31';
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const [cartAnchorEl, setCartAnchorEl] = useState(null);
  const { getCartList, addProduct, subtractProduct, emptyCart } = useCart();

  const isMobile = useMediaQuery('(max-width:1024px)');
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLoginClick = () => {
    setLoginModalOpen(true);
  };

  const handleLoginClose = () => {
    setLoginModalOpen(false);
  };

  const handleCartClick = (event) => {
    getCartList();
    setCartAnchorEl(event.currentTarget);
  };

  const handleCartClose = () => {
    setCartAnchorEl(null);
  };

  const handleAddProduct = async (productId) => {
    addProduct(productId);
  };

  const handleSubtractProduct = async (productId) => {
    subtractProduct(productId);
  };

  const handleEmptyCart = async () => {
    emptyCart();
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCartList();
    }
  }, [getCartList, isAuthenticated]);

  if (isMobile) {
    return (
      <>
        <AppBar
          position="fixed"
          style={{ backgroundColor: isDarkTheme ? '#160F31' : 'white' }}
        >
          <Toolbar>
            <Link to="/" style={{ flexGrow: 1 }}>
              <img
                src={isDarkTheme ? Logo : LogoDark}
                alt="Logo"
                style={{ width: '80px' }}
              />
            </Link>
            {!isAuthenticated ? (
              <IconButton>
                <Button onClick={handleLoginClick}>
                  <img
                    src={isDarkTheme ? Cart : CartDark}
                    alt="Cart"
                    style={{ width: '25.51px' }}
                  />
                </Button>
              </IconButton>
            ) : (
              <CartIconComponent
                isDarkTheme={isDarkTheme}
                textColor={textColor}
              />
            )}
            <IconButton edge="start" onClick={toggleMobileDrawer}>
              <MenuIcon sx={{ color: isDarkTheme ? 'white' : '#F36E1E' }} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="right"
          open={mobileDrawerOpen}
          onClose={toggleMobileDrawer}
          PaperProps={{
            style: {
              backgroundColor: isDarkTheme ? '#160F31' : 'white',
              width: '250px'
            }
          }}
        >
          <div
            style={{
              backgroundColor: isDarkTheme ? '#160F31' : 'white',
              width: '250px'
            }}
          >
            <List>
              <ListItem
                style={{ color: textColor, cursor: 'pointer' }}
                onClick={handleMenuClick}
              >
                {t('globals.navbar.todoSobreWineNFTs')}
                <ArrowDropDownIcon />
              </ListItem>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    backgroundColor: isDarkTheme ? '#160F31' : 'white'
                  }
                }}
              >
                <MenuItem
                  onClick={() => setAnchorEl(null)}
                  component="a"
                  style={{ color: textColor }}
                  href="/about#quienes-somos"
                >
                  {t('globals.navbar.dropdown1')}
                </MenuItem>
                <hr></hr>
                {/*<MenuItem onClick={()=> setAnchorEl(null)} component="a" style={{ color: textColor }} href="/about#team">{t('globals.navbar.dropdown2')}</MenuItem>
                                <hr></hr>*/}
                <MenuItem
                  onClick={() => setAnchorEl(null)}
                  component="a"
                  style={{ color: textColor }}
                  href="/about#como-funciona"
                >
                  {t('globals.navbar.dropdown3')}
                </MenuItem>
                <hr></hr>
                <MenuItem
                  onClick={() => setAnchorEl(null)}
                  component="a"
                  style={{ color: textColor }}
                  href="/about#preguntas-frecuentes"
                >
                  {t('globals.navbar.dropdown4')}
                </MenuItem>
              </Menu>
              <ListItem button component={Link} to="/marketplace">
                <ListItemText
                  style={{ color: textColor }}
                  primary="Marketplace"
                  onClick={toggleMobileDrawer}
                />
              </ListItem>
              <ListItem button component={Link} to="/#bodegas">
                <ListItemText
                  style={{ color: textColor }}
                  primary={t('globals.navbar.bodegas')}
                  onClick={toggleMobileDrawer}
                />
              </ListItem>
              <ListItem button component={Link} to="/marketplace-benefits">
                <ListItemText
                  style={{ color: textColor }}
                  primary={t('globals.navbar.beneficios')}
                  onClick={toggleMobileDrawer}
                />
              </ListItem>
              <ListItem button component={Link} to="/#novedades">
                <ListItemText
                  style={{ color: textColor }}
                  primary={t('globals.navbar.novedades')}
                  onClick={toggleMobileDrawer}
                />
              </ListItem>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignContent="center"
                gap={1}
                sx={{ padding: '0.5rem 1rem' }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  className={'text-gradient'}
                  style={{
                    margin: '0rem',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: '500',
                    cursor: 'pointer'
                  }}
                  gutterBottom
                  onClick={() => i18n.changeLanguage('es')}
                >
                  ES
                </Typography>
                <Typography
                  variant="body1"
                  align="center"
                  className={'text-gradient'}
                  style={{
                    margin: '0rem',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: '500',
                    cursor: 'pointer'
                  }}
                  gutterBottom
                  onClick={() => i18n.changeLanguage('en')}
                >
                  EN
                </Typography>
              </Box>
              <ListItem>
                {!isAuthenticated ? (
                  <Button
                    style={{ color: textColor }}
                    onClick={handleLoginClick}
                    className={
                      isDarkTheme ? 'gradient-border-button' : 'btn-gradient'
                    }
                  >
                    {t('globals.navbar.login')}
                  </Button>
                ) : (
                  <MyAccountMenuComponent
                    isDarkTheme={isDarkTheme}
                    textColor={textColor}
                    toggleMobileDrawer={toggleMobileDrawer}
                  />
                )}
              </ListItem>
            </List>
          </div>
        </Drawer>

        {!isAuthenticated ? (
          <LoginModal
            open={loginModalOpen}
            onClose={handleLoginClose}
            theme={theme}
          />
        ) : (
          ''
        )}
      </>
    );
  }

  return (
    <AppBar
      position="fixed"
      display="flex"
      style={{
        padding: '0rem 2rem',
        backgroundColor: isDarkTheme ? '#160F31' : 'white'
      }}
    >
      <Box display="flex" justifyContent="end" gap={2} pt={1}>
        <Typography
          variant="body1"
          align="center"
          className={'text-gradient'}
          sx={{
            margin: '0rem',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
          }}
          gutterBottom
          onClick={() => i18n.changeLanguage('es')}
        >
          ES
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={'text-gradient'}
          style={{
            margin: '0rem',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '500'
          }}
          gutterBottom
        >
          |
        </Typography>
        <Typography
          variant="body1"
          align="center"
          className={'text-gradient'}
          sx={{
            margin: '0rem',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: '500',
            cursor: 'pointer',
            '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
          }}
          gutterBottom
          onClick={() => i18n.changeLanguage('en')}
        >
          EN
        </Typography>
      </Box>
      <Toolbar
        display="flex"
        sx={{
          width: '100%',
          gap: { md: '0,5rem', lg: '1rem', xl: '4rem' },
          justifyContent: 'center',
          padding: '0rem !important'
        }}
      >
        <Grid
          item
          container
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Button style={{ color: textColor }} onClick={handleMenuClick}>
            {t('globals.navbar.sobreWineNFTs')}
            <ArrowDropDownIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                backgroundColor: isDarkTheme ? '#160F31' : 'white'
              }
            }}
          >
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component="a"
              style={{ color: textColor }}
              href="/about#quienes-somos"
            >
              {t('globals.navbar.dropdown1')}
            </MenuItem>
            <hr></hr>
            {/*<MenuItem onClick={()=> setAnchorEl(null)} component="a" style={{ color: textColor }} href="/about#team">{t('globals.navbar.dropdown2')}</MenuItem>
                        <hr></hr>*/}
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component="a"
              style={{ color: textColor }}
              href="/about#como-funciona"
            >
              {t('globals.navbar.dropdown3')}
            </MenuItem>
            <hr></hr>
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component="a"
              style={{ color: textColor }}
              href="/about#preguntas-frecuentes"
            >
              {t('globals.navbar.dropdown4')}
            </MenuItem>
          </Menu>

          <Button
            sx={{
              display: { xs: 'none', lg: 'flex' },
              width: '120px',
              justifyContent: 'center',
              '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'underline',
                background: 'transparent'
              }
            }}
            style={{ color: textColor }}
            href="/marketplace"
          >
            Marketplace
          </Button>
          <Button
            sx={{
              display: { xs: 'none', lg: 'flex' },
              width: '120px',
              justifyContent: 'center',
              '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'underline',
                background: 'transparent'
              }
            }}
            style={{ color: textColor }}
            href="/#bodegas"
          >
            {t('globals.navbar.bodegas')}
          </Button>
        </Grid>

        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <Link to="/">
            <img
              src={isDarkTheme ? Logo : LogoDark}
              alt="Logo"
              style={{ width: '130px' }}
            />
          </Link>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          sx={{ justifyContent: { xs: 'end', lg: 'space-between' } }}
          flexWrap="nowrap"
        >
          <Button
            sx={{
              display: { xs: 'none', lg: 'flex' },
              width: '120px',
              justifyContent: 'center',
              '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'underline',
                background: 'transparent'
              }
            }}
            style={{ color: textColor }}
            href="/marketplace-benefits"
          >
            {t('globals.navbar.beneficios')}
          </Button>
          <Button
            sx={{
              display: { xs: 'none', lg: 'flex' },
              width: '120px',
              justifyContent: 'center',
              '&:hover': {
                fontWeight: 'bold',
                textDecoration: 'underline',
                background: 'transparent'
              }
            }}
            style={{ color: textColor }}
            href="/#novedades"
          >
            {t('globals.navbar.novedades')}
          </Button>
          <Box
            xs={6}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="nowrap"
            gap={0.5}
          >
            {!isAuthenticated ? (
              <Button onClick={handleLoginClick}>
                <img
                  src={isDarkTheme ? Cart : CartDark}
                  alt="Cart"
                  style={{ width: '25.51px' }}
                />
              </Button>
            ) : (
              <CartIconComponent
                isDarkTheme={isDarkTheme}
                textColor={textColor}
              />
            )}

            {/*<Button href="/checkout"><img src={isDarkTheme ? Len : LenDark} alt="Len" style={{ width: '21px' }}/></Button>*/}

            {!isAuthenticated ? (
              <Button
                style={{ color: textColor }}
                onClick={handleLoginClick}
                className={
                  isDarkTheme ? 'gradient-border-button' : 'btn-gradient'
                }
              >
                {t('globals.navbar.login')}
              </Button>
            ) : (
              <MyAccountMenuComponent
                isDarkTheme={isDarkTheme}
                textColor={textColor}
              />
            )}

            {!isAuthenticated ? (
              <LoginModal
                open={loginModalOpen}
                onClose={handleLoginClose}
                theme={theme}
              />
            ) : (
              ''
            )}
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

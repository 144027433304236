import api from "../api";
import apiToken from "../apiToken";
class BenefitsRepository {
    
    async getBenefitsFiltered(filters, language) {
        try {
            const filteredParams = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/benefit/list?${urlParams}` : '/benefit/list';
            const response = await api.get(endpoint, {headers:{
                "x-locale": language
              }});
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getOwnBenefitsFiltered(filters, language) {
        try {
            const filteredParams = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/benefit/user?${urlParams}` : '/benefit/user';
            const response = await apiToken.get(endpoint, {headers:{
                "x-locale": language
              }});
            var filteredBenefits = [];
            for (let i = 0; i < response.data.length; i++) {
                var status = 0;
                var startDate = new Date(response.data[i].effective?.date);
                var endDate = new Date(response.data[i].expiration?.date);
                if (Date.now() < startDate) {
                    status = 1;
                } else if (Date.now() > startDate && !response.data[i].benefitToken) {
                    status = 2;
                } else if (Date.now() > startDate && response.data[i].benefitToken && response.data[i].remaining) {
                    status = 3;
                } else if (Date.now() > startDate && response.data[i].benefitToken && !response.data[i].remaining) {
                    status = 4;
                } else if (Date.now() > endDate) {
                    status = 5;
                }
                filteredBenefits.push({...response.data[i], state: status});
            }
            return filteredBenefits;
        } catch (error) {
            throw error;
        }
    }

    async getCommerceBenefitsFiltered(filters, language) {
        try {
            const filteredParams = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/benefit/commerce?${urlParams}` : '/benefit/commerce';
            const response = await apiToken.get(endpoint, {headers:{
                "x-locale": language
              }});
            var filteredBenefits = [];
            for (let i = 0; i < response.data.length; i++) {
                var status = 0;
                var startDate = new Date(response.data[i].effective?.date);
                var endDate = new Date(response.data[i].expiration?.date);
                if (Date.now() < startDate) {
                    status = 1;
                } else if (Date.now() > startDate && !response.data[i].benefitToken) {
                    status = 2;
                } else if (Date.now() > startDate && response.data[i].benefitToken && response.data[i].remaining) {
                    status = 3;
                } else if (Date.now() > startDate && response.data[i].benefitToken && !response.data[i].remaining) {
                    status = 4;
                } else if (Date.now() > endDate) {
                    status = 5;
                }
                filteredBenefits.push({...response.data[i], state: status});
            }
            return filteredBenefits;
        } catch (error) {
            throw error;
        }
    }

    async getOwnBenefitsRedeemFiltered(filters, language) {
        try {
            const filteredParams = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const urlParams = new URLSearchParams(filteredParams).toString();
            const endpoint = urlParams ? `/benefit/redeem?${urlParams}` : '/benefit/redeem';
            const response = await apiToken.get(endpoint, {headers:{
                "x-locale": language
              }});
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async redeemBenefit(nft, benefit) {
        try {
            const body = {
                tokenId: nft,
                benefitId: benefit
            }
            const endpoint = '/benefit/redeem';
            const response = await apiToken.post(endpoint, body);
            return response.data;
        } catch (error) {
            throw error.response ? error.response.data.message : error.message ;
        }
    }

    async downloadBenefit(nft) {
        try {
            const endpoint = `/benefit/redeem/${nft}`;
            const response = await apiToken.get(endpoint);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async findTokenBenefitById(nft) {
        try {
            const response = await apiToken.get(`/benefit/user/token/${nft}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async validateBenefit(nft, amount) {
        try {
            const body = {
                benefitTokenId: nft,
                amount: amount
            }
            const endpoint = '/benefit/validate';
            const response = await apiToken.post(endpoint, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getUserPoints() {
        try {
            const response = await apiToken.get(`/user/points`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getBenefitCategories() {
        try {
            const response = await api.get(`/benefit/categories/list`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

export default BenefitsRepository;
import React from "react";
import WineryCollateralsComponent from "../components/profile/WineryCollateralsComponent";

const WineryCollateralsView = () => {
  return (
    <div>
      <WineryCollateralsComponent />
    </div>
  );
};

export default WineryCollateralsView;

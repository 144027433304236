import React from "react";
import RegisterFinancialForm from "../components/accounts/RegisterFinancialForm";

const RegisterFinancialView = () => {
  return (
    <div>
      <RegisterFinancialForm />
    </div>
  );
};

export default RegisterFinancialView;

export default class RequestPassword {
    constructor(email) {
        this.validate(email);
        this.email = email;
    }

    validate({ email }) {
        let errors = {};

        if (this.isValidEmail(email)) {
            errors.email = this.isValidEmail(email);
        }

        return errors;
    }

    isValidEmail(email) {

        if (!email) {
            return 'El campo email no puede estar vacío';
        }

        const re = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(!re.test(String(email).toLowerCase())) {
            return 'El email no es válido';
        }

        return null
    }
}


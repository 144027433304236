import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Menu,
  MenuItem,
  Link,
  Box,
  Pagination,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  TextField
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductRepository from "../../../data/repository/ProductRepository";
import CollateralRepository from "../../../data/repository/CollateralRepository";
import SuccessDialog from "../SuccessDialog";
import ErrorDialog from "../ErrorDialog";
import { useTranslation, Trans } from "react-i18next";
import CellarRepository from "../../../data/repository/CellarRepository";

const FinancialCollateralsComponent = () => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const productRepository = new ProductRepository();
  const cellarRepository = new CellarRepository();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCollateralId, setSelectedCollateralId] = useState(null);
  const collateralRepository = new CollateralRepository();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSelected, setPageSelected] = useState(1);
  const [areRowsLoading, setAreRowsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [openModalTransfer, setOpenModalTransfer] = useState(false);
  const [openModalBurn, setOpenModalBurn] = useState(false);
  const [cellars, setCellars] = useState([]);
  const [cellarSelectedToTransfer, setSelectedCellarToTransfer] = useState("");
  const [getWineriesLoading, setGetWineriesLoading] = useState(false);
  const [putOnSaleLoading, setPutOnSaleLoading] = useState(false);
  const [putOffSaleLoading, setPutOffSaleLoading] = useState(false);
  const [transferCollateralLoading, setTransferCollateralLoading] =
    useState(false);
  const [cancelInstallmentLoading, setCancelInstallmentLoading] =
    useState(false);

  const fetchFinancialCollaterals = async (page) => {
    setAreRowsLoading(true);
    try {
      const collaterals = await productRepository.findFinancialCollaterals(
        page ?? pageSelected
      );
      setRows(collaterals.rows);
      setNumberOfPages(
        Math.ceil(
          Number(collaterals.pagination?.count / collaterals.pagination?.limit)
        )
      );
    } catch (error) {
      console.error("Error al obtener colaterales del usuario:", error);
    }
    setAreRowsLoading(false);
  };

  const clickPaginationColaterals = async (event, page) => {
    if (!areRowsLoading) {
      fetchFinancialCollaterals(page).then(() => setPageSelected(page));
    }
  };

  useEffect(() => {
    fetchFinancialCollaterals();
  }, []);

  const handleClick = (event, collateralId) => {
    handleClose();
    setAnchorEl(event.currentTarget);
    setSelectedCollateralId(collateralId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCollateralId(null);
  };

  const handleSell = async () => {
    setPutOnSaleLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.putUpInSale(
        selectedCollateralId,
        price
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.sell.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001" ? (
            t("myNFTs.error1")
          ) : result.message === "sale.error.00002" ? (
            t("myNFTs.error2")
          ) : result.message === "sale.error.00003" ? (
            <Trans
              i18nKey="myNFTs.error3"
              components={[
                <Link href="/profile/user-data" style={{ margin: "1px" }} />
              ]}
            />
          ) : result.message === "sale.error.00004" ? (
            t("myNFTs.error4")
          ) : result.message === "sale.error.00005" ? (
            t("myNFTs.error5")
          ) : result.message === "sale.error.00006" ? (
            t("myNFTs.error6")
          ) : result.message === "sale.error.00007" ? (
            t("myNFTs.error7")
          ) : result.message === "sale.error.00008" ? (
            t("myNFTs.error8")
          ) : result.message === "token_not_found" ? (
            t("myNFTs.error9")
          ) : result.message === "token_paid" ? (
            t("myNFTs.error10")
          ) : (
            "Error: " + result.message
          )
        );
      }
      setOpenModal(false);
      handleClose();
    }
    setPutOnSaleLoading(false);
  };

  const fetchCellars = async () => {
    setGetWineriesLoading(true);
    try {
      const response = await cellarRepository.getCellarList();
      setCellars(response?.map((cellar) => cellar));
    } catch (error) {
      console.error("Error fetching cellars:", error);
    } finally {
      setGetWineriesLoading(false);
    }
  };

  const handleModalTransfer = () => {
    setOpenModalTransfer(true);
    fetchCellars();
  };

  const handleTransfer = async () => {
    setTransferCollateralLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.transferCollateral(
        selectedCollateralId,
        cellarSelectedToTransfer
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.transfer.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "collateral.transfer.error.00001"
            ? t("myNFTs.transfer.error1")
            : result.message === "collateral.transfer.error.00002"
            ? t("myNFTs.transfer.error2")
            : result.message === "collateral.transfer.error.00003"
            ? t("myNFTs.transfer.error3")
            : result.message === "collateral.transfer.error.00004"
            ? t("myNFTs.transfer.error4")
            : result.message === "collateral.transfer.error.00005"
            ? t("myNFTs.transfer.error5")
            : result.message === "collateral.transfer.error.00006"
            ? t("myNFTs.transfer.error6")
            : "Error: " + result.message
        );
      }
      setOpenModalTransfer(false);
      handleClose();
    }
    setTransferCollateralLoading(false);
  };

  const handleCancelSale = async () => {
    setPutOffSaleLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.cancelSale(
        selectedCollateralId
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.cancel.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : result.message === "token_not_found"
            ? t("myNFTs.error9")
            : "Error: " + result.message
        );
      }
      setOpenModalCancel(false);
      handleClose();
    }
    setPutOffSaleLoading(false);
  };

  const handleBurnCollateral = async () => {
    setCancelInstallmentLoading(true);
    if (selectedCollateralId) {
      const result = await collateralRepository.cancelInstallment(
        selectedCollateralId
      );

      if (result.success) {
        setSuccess(t("myNFTs.modals.burn.success"));
        fetchFinancialCollaterals();
      } else {
        setError(
          result.message === "sale.error.00001"
            ? t("myNFTs.error1")
            : result.message === "sale.error.00002"
            ? t("myNFTs.error2")
            : result.message === "sale.error.00003"
            ? t("myNFTs.error3")
            : result.message === "sale.error.00004"
            ? t("myNFTs.error4")
            : result.message === "sale.error.00005"
            ? t("myNFTs.error5")
            : result.message === "sale.error.00006"
            ? t("myNFTs.error6")
            : result.message === "sale.error.00007"
            ? t("myNFTs.error7")
            : result.message === "token_not_found"
            ? t("myNFTs.error9")
            : "Error: " + result.message
        );
      }
      setOpenModalBurn(false);
      handleClose();
    }
    setCancelInstallmentLoading(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModalCancel(false);
    setOpenModalTransfer(false);
    setOpenModalBurn(false);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSelectedCellarToTransfer(value);
  };

  const [price, setPrice] = useState();

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem"
        }}
      >
        {t("myNFTs.misColaterales")}
      </Typography>

      <TableContainer component={Paper} style={{ marginTop: "3rem" }}>
        <Table aria-label="tabla de winenfts">
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.bodega")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.vino")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.plazo")}
              </TableCell>
              <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                {t("myNFTs.estado")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.cellar.name}</TableCell>
                <TableCell>{row.productCollateral.name}</TableCell>
                <TableCell>{row.installmentNumber}</TableCell>
                <TableCell>
                  {row.isBuyback ? (
                    <span style={{ color: "green" }}>
                      {t("myNFTs.buybackVendido")}
                    </span>
                  ) : row.inSale ? (
                    <span style={{ color: "green" }}>
                      {t("myNFTs.enVenta")}
                    </span>
                  ) : !row.isInstallmentPaid ? (
                    <span style={{ color: "orange" }}>
                      {t("myNFTs.pendientePago")}
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>{t("myNFTs.pagado")}</span>
                  )}
                </TableCell>
                <TableCell>
                  {row.isBuyback ? (
                    "-"
                  ) : !row.isBuyback && !row.isInstallmentPaid ? (
                    <>
                      <span
                        style={{
                          color: "#F36E1E",
                          textDecoration: "underline",
                          cursor: "pointer"
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClick(event, row.id);
                        }}
                      >
                        {t("myNFTs.acciones")}
                      </span>
                      <Menu
                        anchorEl={anchorEl}
                        open={selectedCollateralId === row.id}
                        onClose={handleClose}
                      >
                        {!row.inSale ? (
                          <>
                            <MenuItem
                              key="sell"
                              onClick={() => setOpenModal(true)}
                            >
                              {t("myNFTs.confirmarVenta")}
                            </MenuItem>
                            <MenuItem
                              key="cancel"
                              onClick={() => setOpenModalBurn(true)}
                            >
                              {t("myNFTs.cancelarPlazo")}
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem
                              key="transfer"
                              onClick={() => handleModalTransfer()}
                            >
                              {t("myNFTs.transferirColateral")}
                            </MenuItem>
                            <MenuItem
                              key="sell"
                              onClick={() => setOpenModalCancel(true)}
                            >
                              {t("myNFTs.cancelarVenta")}
                            </MenuItem>
                          </>
                        )}
                      </Menu>
                    </>
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" mt={2} justifyContent={"center"}>
        <Pagination
          count={numberOfPages}
          defaultPage={pageSelected}
          siblingCount={2}
          onChange={clickPaginationColaterals}
          disabled={areRowsLoading}
        />
      </Box>

      <SuccessDialog
        open={!!success}
        onClose={() => setSuccess(null)}
        alertTitle={t("myNFTs.success")}
        alertMessage={success}
        dark={false}
      />

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("myNFTs.error")}
        alertMessage={error}
        dark={false}
      />

      {/* MODAL SELL */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.modals.sell.title")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1">
            {t("myNFTs.modals.sell.sub_title")}
          </Typography>
        </DialogContent>
        <DialogContent>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              type="number"
              label={t("myNFTs.modals.sell.price")}
              name="residence"
              value={price}
              slotProps={{ inputLabel: { shrink: true } }}
              onChange={(e) => setPrice(e.target.value)}
              InputProps={{
                style: { borderRadius: "55px" },
                inputProps: { min: 0 }
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.atras")}
          </Button>
          <Button
            onClick={putOnSaleLoading ? null : handleSell}
            className={"btn-gradient"}
            disabled={putOnSaleLoading || !price}
          >
            {t("myNFTs.modals.sell.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL CANCEL SELL */}
      <Dialog open={openModalCancel} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.modals.cancel.title")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1">
            {t("myNFTs.modals.cancel.sub_title")}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.atras")}
          </Button>
          <Button
            onClick={putOffSaleLoading ? null : handleCancelSale}
            className={"btn-gradient"}
            disabled={putOffSaleLoading}
          >
            {t("myNFTs.modals.cancel.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL TRANSFER */}
      <Dialog open={openModalTransfer} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.modals.transfer.title")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1">
            {t("myNFTs.modals.transfer.sub_title")}
          </Typography>
        </DialogContent>
        <DialogContent>
          {getWineriesLoading ? (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", padding: "1rem" }}
            >
              {t("myNFTs.modals.transfer.loadingWineries")}
            </Typography>
          ) : (
            <FormControl fullWidth variant="outlined">
              <InputLabel sx={{ background: "white" }}>
                {t("myNFTs.modals.transfer.select")}
              </InputLabel>

              <Select
                name="cellars"
                onChange={handleInputChange}
                input={
                  <OutlinedInput
                    style={{
                      borderRadius: "55px"
                    }}
                  />
                }
                label={t("marketplace.cellars")}
              >
                {cellars?.map((cellar) => (
                  <MenuItem key={cellar.id} value={cellar.id}>
                    {cellar.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.atras")}
          </Button>
          <Button
            onClick={
              transferCollateralLoading || getWineriesLoading
                ? null
                : handleTransfer
            }
            className={"btn-gradient"}
            disabled={transferCollateralLoading || getWineriesLoading}
          >
            {t("myNFTs.modals.transfer.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL BURN */}
      <Dialog open={openModalBurn} onClose={handleCloseModal}>
        <DialogTitle>{t("myNFTs.modals.burn.title")}</DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <Typography variant="body1">
            {t("myNFTs.modals.burn.sub_title")}
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
          }}
        >
          <Button onClick={handleCloseModal} className={"btn-outline-gradient"}>
            {t("myNFTs.atras")}
          </Button>
          <Button
            onClick={cancelInstallmentLoading ? null : handleBurnCollateral}
            className={"btn-gradient"}
            disabled={cancelInstallmentLoading}
          >
            {t("myNFTs.modals.burn.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FinancialCollateralsComponent;

import api from "../api";
class BrandRepository {

    async getBrandList() {
        try {
            const response = await api.get('/brand/list');
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default BrandRepository;
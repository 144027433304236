import React from "react";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const WineryAdquiredCollateralsComponent = (rows) => {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper} style={{ marginTop: "3rem" }}>
      {rows.rows.length > 0 ? (
        <Table aria-label="tabla de winenfts">
          <>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                  {t("myNFTs.wineryCollateralsTable.winery")}
                </TableCell>
                <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                  {t("myNFTs.wineryCollateralsTable.collateralName")}
                </TableCell>
                <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                  {t("myNFTs.wineryCollateralsTable.liters")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.rows?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.cellar.name}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{`${row.liters}L`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      ) : (
        t("myNFTs.collateralsNoResults")
      )}
    </TableContainer>
  );
};

export default WineryAdquiredCollateralsComponent;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/en.json";
import translationES from "./locales/es/es.json";

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

const DETECTION_OPTIONS = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
  ],
};

const languages = ["en", "es"];

const fallbackLng = "es";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: DETECTION_OPTIONS,
    interpolation: { escapeValue: false },
    fallbackLng: fallbackLng,
    whitelist: languages,
    resources: resources,
  });

export default i18n;

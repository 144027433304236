import React from "react";
import UserBenefitsComponent from "../components/profile/UserBenefitsComponent";


const UserBenefitsView = () => {

    return (
        <div>
            <UserBenefitsComponent />
        </div>
    );
}

export default UserBenefitsView;
import React, { useEffect } from "react";
import MarketplaceComponent from "../components/marketplace/MarketplaceComponent";


const MarketplaceView = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <MarketplaceComponent />
        </div>
    );
}

export default MarketplaceView;
import React from "react";
import { Box, Typography, Grid, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Star from "../../assets/images/Star.png";
import { useTranslation } from "react-i18next";

const HomeBenefitsInfo = () => {
  const { t } = useTranslation();
  const items = [
    t("home.benefits.items.premios"),
    t("home.benefits.items.eventos"),
    t("home.benefits.items.descuentos"),
    t("home.benefits.items.vip"),
    t("home.benefits.items.catas"),
    t("home.benefits.items.regalos"),
    t("home.benefits.items.comunidad"),
    t("home.benefits.items.puntos"),
  ];

  var navigate = useNavigate();

  return (
    <Container id="beneficios-landing" maxWidth="xl">
      <Grid
        container
        spacing={4}
        px={0}
        mt={12}
        mb={12}
        mx={0}
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
          <Typography
            variant="h3"
            sx={{
              color: "white",
              fontSize: { xs: "20px", sm: "40px" },
              fontWeight: "bold",
            }}
            align="center"
          >
            {t("home.benefits.title")}
          </Typography>
        </Grid>

        <Grid item xs={0} sm={2} sx={{ paddingLeft: "0px !important" }} />
        <Grid item xs={12} sm={8} mb={2} sx={{ paddingLeft: "0px !important" }}>
          <Typography
            variant="body1"
            sx={{ color: "white", fontSize: { xs: "14px", sm: "20px" } }}
            align="center"
          >
            {t("home.benefits.subtitle")}
          </Typography>
        </Grid>
        <Grid item xs={0} sm={2} sx={{ paddingLeft: "0px !important" }} />

        <Box
          sx={{
            paddingLeft: "0px !important",
            width: "calc(100% - 32px)",
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {items?.map((item, index) => (
            <Box
              display="flex"
              alignItems="center"
              width="332px"
              style={{
                background: "linear-gradient(to right, #FC6964, #FCC442)",
                padding: "2px",
                boxSizing: "border-box",
                borderRadius: "10px",
                margin: "5px",
              }}
            >
              <Box
                display="flex"
                alignItems={"center"}
                style={{
                  backgroundColor: "#160F31",
                  width: "100%",
                  height: "100%",
                  borderRadius: "7px",
                  padding: "0.75rem",
                }}
              >
                <img
                  src={Star}
                  alt={`Benefit ${index}`}
                  style={{ width: "38px", height: "38px", padding: "20px" }}
                />
                <Typography
                  variant="body1"
                  sx={{ color: "white", fontSize: { xs: "16px", sm: "20px" } }}
                >
                  {item}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Grid container justifyContent="center" mt={8}>
          <Button
            onClick={() => navigate("/benefits")}
            className={"btn-gradient"}
            style={{ color: "white" }}
          >
            {t("home.benefits.button")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomeBenefitsInfo;

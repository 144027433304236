import React from "react";
import {
  Grid,
  Avatar,
  Typography,
  IconButton,
  Container,
  Box,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DiegoAvatar from "../../assets/images/diego-ledda.png";
import CarlosAvatar from "../../assets/images/carlos-moyano.png";
import JoseAvatar from "../../assets/images/jose-salazar.png";
import ArianoAvatar from "../../assets/images/ariano-hernandez.png";
import MartinAvatar from "../../assets/images/martin-arroyo.png";
import OaroAvatar from "../../assets/images/partner-oaro.png";
import AgrotokenAvatar from "../../assets/images/partner-agrotoken.png";
import { useTranslation } from "react-i18next";

const TeamComponent = () => {
  const { t } = useTranslation();
  const executiveTeam = [
    {
      imageUrl: DiegoAvatar,
      name: "DIEGO LEDDA",
      description: t("about.team.co-founder"),
      linkedInUrl: "https://www.linkedin.com/in/diego-ledda-64134713",
    },
    {
      imageUrl: CarlosAvatar,
      name: "CARLOS MOYANO",
      description: t("about.team.co-founder"),
      linkedInUrl: "https://www.linkedin.com/in/carlos-moyano-58872b34",
    },
    {
      imageUrl: JoseAvatar,
      name: "JOSÉ ZALAZAR",
      description: "CTO",
      linkedInUrl: "https://www.linkedin.com/in/jose-gabriel-zalazar",
    },
  ];

  const advisors = [
    {
      imageUrl: MartinAvatar,
      name: "MARTÍN ARROYO",
      description: "ADVISOR",
      linkedInUrl: "https://www.linkedin.com/in/martinarroyo01",
    },
    {
      imageUrl: ArianoAvatar,
      name: "ARIANO HERNÁNDEZ",
      description: "TECH ADVISOR",
      linkedInUrl: "https://www.linkedin.com/in/arianohernandez",
    },
  ];

  const worldClassPartners = [
    {
      imageUrl: AgrotokenAvatar,
      name: "AGROTOKEN",
      description: "PARTNER",
      linkedInUrl: "https://www.linkedin.com/company/agrotoken/mycompany",
    },
    {
      imageUrl: OaroAvatar,
      name: "OARO",
      description: "PARTNER",
      linkedInUrl: "https://www.linkedin.com/company/oarotechnology/",
    },
  ];

  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          gap: { xs: "2rem", sm: "10rem" },
        }}
        justifyContent="center"
        alignItems="center"
        mb={6}
      >
        {/*<Grid item xs={12} mb={6}>
                    <Typography variant="h3" sx={{color: 'white', fontSize: {xs: '20px', sm: '40px'}, fontWeight: 'bold'}} align="center">
                        Team
                    </Typography>
                </Grid>*/}

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            width: { xs: "100%", sm: "33%" },
            maxWidth: { xs: "none", sm: "300px" },
          }}
          style={{ alignSelf: "stretch" }}
        >
          <Typography
            variant="h4"
            style={{ color: "white", fontSize: "20px" }}
            align="center"
          >
            Executive Team
          </Typography>

          <Grid container spacing={1} justifyContent={"center"} mt={3}>
            {executiveTeam?.map((member, index) => (
              <Grid item xs={12} sm={6} key={index} style={{ maxWidth: 150 }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt={member.name}
                      src={member.imageUrl}
                      style={{ width: 120, height: 120 }}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {member.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ fontSize: "12px", color: "white" }}
                    >
                      {member.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      href={member.linkedInUrl}
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <LinkedInIcon style={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            width: { xs: "100%", sm: "33%" },
            maxWidth: { xs: "none", sm: "300px" },
          }}
          style={{ alignSelf: "stretch" }}
        >
          <Typography
            variant="h4"
            style={{ color: "white", fontSize: "20px" }}
            align="center"
          >
            Advisors
          </Typography>

          <Grid container spacing={1} justifyContent={"center"} mt={3}>
            {advisors?.map((member, index) => (
              <Grid item xs={12} sm={6} key={index} style={{ maxWidth: 150 }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt={member.name}
                      src={member.imageUrl}
                      style={{ width: 120, height: 120 }}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {member.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ fontSize: "12px", color: "white" }}
                    >
                      {member.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      href={member.linkedInUrl}
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <LinkedInIcon style={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            width: { xs: "100%", sm: "33%" },
            maxWidth: { xs: "none", sm: "300px" },
          }}
          style={{ alignSelf: "stretch" }}
        >
          <Typography
            variant="body1"
            style={{ color: "white", fontSize: "20px" }}
            align="center"
          >
            World Class Partners
          </Typography>

          <Grid container spacing={1} justifyContent={"center"} mt={3}>
            {worldClassPartners?.map((member, index) => (
              <Grid item xs={12} sm={6} key={index} style={{ maxWidth: 150 }}>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt={member.name}
                      src={member.imageUrl}
                      style={{ width: 120, height: 120 }}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {member.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      align="center"
                      style={{ fontSize: "12px", color: "white" }}
                    >
                      {member.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      href={member.linkedInUrl}
                      target="_blank"
                      aria-label="LinkedIn"
                    >
                      <LinkedInIcon style={{ color: "white" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TeamComponent;

export default class Subscriber {
    constructor(email) {
        this.isValidEmail(email);
        this.email = email;
    }

    isValidEmail(email){
        const regex = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!email || email.trim() === '') {
            throw new Error('Email no puede estar vacío.');
        }

        if (!regex.test(email)) {
            throw new Error("Invalid email: " + email);
        };
    }
}
const MIN_NAME_LENGTH = 2;
const MIN_PASSWORD_LENGTH = 8;
const MIN_AGE = 18;

export default class User {
  constructor(user) {
    this.validateUser(user);
    Object.assign(this, user);
  }

  validateUser(
    {
      name,
      surname,
      email,
      phone,
      gender,
      birthdate,
      residence,
      cp,
      country,
      city,
      password,
      confirmPassword,
      acceptTerms,
    },
    isGoogle
  ) {
    let errors = {};

    if (!this.isValidPhone(phone)) {
      errors.phone = "registerForm.noValidPhone";
    }

    if (!this.isValidGender(gender)) {
      errors.gender = "registerForm.emptySelect";
    }

    if (this.isValidAge(birthdate)) {
      errors.birthdate = this.isValidAge(birthdate);
    }

    if (!this.isValidText(residence, "residence")) {
      errors.residence = "registerForm.noValidField";
    }

    if (!this.isValidText(cp, "cp")) {
      errors.cp = "registerForm.noValidField";
    }

    if (!this.isValidText(country, "country")) {
      errors.country = "registerForm.noValidField";
    }

    if (!this.isValidText(city, "city")) {
      errors.city = "registerForm.noValidField";
    }

    if (!isGoogle) {
      if (!this.isValidText(name, "name")) {
        errors.name = "registerForm.noValidField";
      }

      if (!this.isValidText(surname, "surname")) {
        errors.surname = "registerForm.noValidField";
      }

      if (!this.isValidEmail(email)) {
        errors.email = "registerForm.noValidEmail";
      }

      if (this.isValidPassword(password, confirmPassword)) {
        errors.password = this.isValidPassword(password, confirmPassword);
      }
    }

    return errors;
  }

  isValidText(text, fieldName) {
    if (!text || text.length < MIN_NAME_LENGTH) {
      return false;
    }

    return true;
  }

  isValidEmail(email) {
    const re = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  }

  isValidPhone(phone) {
    const re = /^[+][(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,13}$/;
    return re.test(String(phone));
  }

  isValidGender(gender) {
    return ["male", "female", "undefined"].includes(gender);
  }

  isValidPassword(password, confirmPassword) {
    if (password.length < MIN_PASSWORD_LENGTH) {
      return MIN_PASSWORD_LENGTH;
    }

    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!hasNumber.test(password)) {
      return "registerForm.passwordNumber";
    }

    if (!hasSymbol.test(password)) {
      return "registerForm.passwordSymbol";
    }

    if (password !== confirmPassword) {
      return "registerForm.passwordMatch";
    }

    return null;
  }

  isValidAge(birthdate) {
    if (!birthdate) {
      return "registerForm.emptyField";
    }

    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < MIN_AGE) {
      return MIN_AGE;
    }

    return null;
  }
}

export class Commerce {
  constructor(commerce) {
    this.validateCommerce(commerce);
    Object.assign(this, commerce);
  }

  validateCommerce(
    {
      businessName,
      tax,
      email,
      phone,
      cellars,
      category,
      residence,
      cp,
      country,
      city,
      password,
      confirmPassword,
      acceptTerms,
    },
    isGoogle
  ) {
    let errors = {};

    if (!this.isValidPhone(phone)) {
      errors.phone = "registerForm.noValidPhone";
    }

    if (!this.isValidCellars(cellars)) {
      errors.cellars = "registerForm.emptySelect";
    }

    if (!this.isValidCategory(category)) {
      errors.category = "registerForm.emptySelect";
    }

    if (!this.isValidText(residence, "residence")) {
      errors.residence = "registerForm.noValidField";
    }

    if (!this.isValidText(cp, "cp")) {
      errors.cp = "registerForm.noValidField";
    }

    if (!this.isValidText(country, "country")) {
      errors.country = "registerForm.noValidField";
    }

    if (!this.isValidText(city, "city")) {
      errors.city = "registerForm.noValidField";
    }

    if (!isGoogle) {
      if (!this.isValidText(businessName, "businessName")) {
        errors.businessName = "registerForm.noValidField";
      }

      if (!this.isValidText(tax, "tax")) {
        errors.tax = "registerForm.noValidField";
      }

      if (!this.isValidEmail(email)) {
        errors.email = "registerForm.noValidEmail";
      }

      if (this.isValidPassword(password, confirmPassword)) {
        errors.password = this.isValidPassword(password, confirmPassword);
      }
    }

    return errors;
  }

  isValidText(text, fieldName) {
    if (!text || text.length < MIN_NAME_LENGTH) {
      return false;
    }

    return true;
  }

  isValidEmail(email) {
    const re = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  }

  isValidPhone(phone) {
    const re = /^[+][(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,13}$/;
    return re.test(String(phone));
  }

  isValidPassword(password, confirmPassword) {
    if (password.length < MIN_PASSWORD_LENGTH) {
      return MIN_PASSWORD_LENGTH;
    }

    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!hasNumber.test(password)) {
      return "registerForm.passwordNumber";
    }

    if (!hasSymbol.test(password)) {
      return "registerForm.passwordSymbol";
    }

    if (password !== confirmPassword) {
      return "registerForm.passwordMatch";
    }

    return null;
  }

  isValidCellars(cellars) {
    return cellars.length;
  }

  isValidCategory(category) {
    return category;
  }
}

export class Winery {
  constructor(winery) {
    this.validateWinery(winery);
    Object.assign(this, winery);
  }

  validateWinery(
    {
      name,
      email,
      phone,
      residence,
      cp,
      country,
      city,
      password,
      confirmPassword,
      acceptTerms,
    },
    isGoogle
  ) {
    let errors = {};

    if (!this.isValidPhone(phone)) {
      errors.phone = "registerForm.emptyPhone";
    }

    if (!this.isValidText(residence, "residence")) {
      errors.residence = "registerForm.noValidField";
    }

    if (!this.isValidText(cp, "cp")) {
      errors.cp = "registerForm.noValidField";
    }

    if (!this.isValidText(country, "country")) {
      errors.country = "registerForm.noValidField";
    }

    if (!this.isValidText(city, "city")) {
      errors.city = "registerForm.noValidField";
    }

    if (!isGoogle) {
      if (!this.isValidText(name, "name")) {
        errors.tax = "registerForm.noValidField";
      }

      if (!this.isValidEmail(email)) {
        errors.email = "registerForm.noValidEmail";
      }

      if (this.isValidPassword(password, confirmPassword)) {
        errors.password = this.isValidPassword(password, confirmPassword);
      }
    }

    return errors;
  }

  isValidText(text, fieldName) {
    if (!text || text.length < MIN_NAME_LENGTH) {
      return false;
    }

    return true;
  }

  isValidEmail(email) {
    const re = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  }

  isValidPhone(phone) {
    const re = /\+\d{1,20}/;
    return re.test(String(phone));
  }

  isValidPassword(password, confirmPassword) {
    if (password.length < MIN_PASSWORD_LENGTH) {
      return MIN_PASSWORD_LENGTH;
    }

    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!hasNumber.test(password)) {
      return "registerForm.passwordNumber";
    }

    if (!hasSymbol.test(password)) {
      return "registerForm.passwordSymbol";
    }

    if (password !== confirmPassword) {
      return "registerForm.passwordMatch";
    }

    return null;
  }
}

export class Financial {
  constructor(financial) {
    this.validateFinancial(financial);
    Object.assign(this, financial);
  }

  validateFinancial(
    {
      name,
      email,
      phone,
      residence,
      cp,
      country,
      city,
      password,
      confirmPassword,
      acceptTerms,
    },
    isGoogle
  ) {
    let errors = {};

    if (!this.isValidPhone(phone)) {
      errors.phone = "registerForm.noValidPhone";
    }

    if (!this.isValidText(residence, "residence")) {
      errors.residence = "registerForm.noValidField";
    }

    if (!this.isValidText(cp, "cp")) {
      errors.cp = "registerForm.noValidField";
    }

    if (!this.isValidText(country, "country")) {
      errors.country = "registerForm.noValidField";
    }

    if (!this.isValidText(city, "city")) {
      errors.city = "registerForm.noValidField";
    }

    if (!isGoogle) {
      if (!this.isValidText(name, "name")) {
        errors.name = "registerForm.noValidField";
      }

      if (!this.isValidEmail(email)) {
        errors.email = "registerForm.noValidEmail";
      }

      if (this.isValidPassword(password, confirmPassword)) {
        errors.password = this.isValidPassword(password, confirmPassword);
      }
    }

    return errors;
  }

  isValidText(text, fieldName) {
    if (!text || text.length < MIN_NAME_LENGTH) {
      return false;
    }

    return true;
  }

  isValidEmail(email) {
    const re = /^[a-zA-Z0-9+._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(String(email).toLowerCase());
  }

  isValidPhone(phone) {
    const re = /^[+][(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,13}$/;
    return re.test(String(phone));
  }

  isValidPassword(password, confirmPassword) {
    if (password.length < MIN_PASSWORD_LENGTH) {
      return MIN_PASSWORD_LENGTH;
    }

    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!hasNumber.test(password)) {
      return "registerForm.passwordNumber";
    }

    if (!hasSymbol.test(password)) {
      return "registerForm.passwordSymbol";
    }

    if (password !== confirmPassword) {
      return "registerForm.passwordMatch";
    }

    return null;
  }
}

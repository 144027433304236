import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Container,
  Box,
  DialogContent,
  Dialog,
  CardMedia,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import BenefitsRepository from "../../../data/repository/BenefitsRepository";
import { Link, useNavigate } from "react-router-dom";

const UserPointsComponent = () => {
  const [t, i18n] = useTranslation();

  const { isAuthenticated, roles } = useAuth();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [areRowsLoading, setAreRowsLoading] = useState(false);
  const benefitsRepository = new BenefitsRepository();

  const fetchUserPoints = async (usedFilter) => {
    setAreRowsLoading(true);
    try {
      const points = await benefitsRepository.getUserPoints();
      setRows(points);
    } catch (error) {
      console.error("Error al obtener los puntos del usuario:", error);
    }
    setAreRowsLoading(false);
  };

  useEffect(() => {
    fetchUserPoints();
  }, [i18n.language]);

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "1rem" }}>
      <Typography
        variant="h1"
        style={{
          color: "#F36E1E",
          padding: "10px",
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "2rem",
        }}
      >
        {t("myPoints.title")}
      </Typography>
      {!rows.length ? (
        <Box
          sx={{
            width: "calc(100% - 32px)",
            display: "flex",
            padding: "1rem",
            gap: { xs: "0.5rem", sm: "1rem" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="p"
            sx={{
              width: "100%",
              textAlign: "center",
              color: "red",
              fontSize: { xs: "12px", sm: "16px" },
            }}
          >
            {areRowsLoading
              ? t("myPoints.loadingPoints")
              : t("myPoints.emptyPoints")}
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "3rem" }}>
          <Table aria-label="points-table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                  {t("myNFTs.bodega")}
                </TableCell>
                <TableCell style={{ color: "#F36E1E", fontWeight: "bold" }}>
                  {t("myPoints.dkfsAccumulated")}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row?.name}</TableCell>
                  <TableCell>{row?.points}</TableCell>
                  <TableCell>
                    <Link
                      to={`/marketplace-benefits?type=points&cellar=${row.id}`}
                    >
                      <span>{t("myPoints.redeem")}</span>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default UserPointsComponent;

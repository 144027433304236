import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  OutlinedInput,
  Typography,
  Hidden,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserRepository from "../../../data/repository/UserRepository";
import { Commerce } from "../../../core/entity/User";
import ErrorDialog from "../ErrorDialog";
import { useTranslation } from "react-i18next";
import CellarRepository from "../../../data/repository/CellarRepository";
import CategoriesRepository from "../../../data/repository/CategoriesRepository";

const RegisterCommerceForm = () => {
  const location = useLocation();

  const [t, i18n] = useTranslation();

  const cellarRepository = new CellarRepository();
  const categoriesRepository = new CategoriesRepository();
  const [cellars, setCellars] = useState([]);
  const [areCellarsLoading, setAreCellarsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [areCategoriesLoading, setAreCategoriesLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const googleUser = location.state?.user?.data;
  const googleToken = location.state?.token;
  const [formData, setFormData] = useState({
    businessName: "",
    tax: "",
    email: "",
    phone: "",
    cellars: [],
    category: "",
    residence: "",
    cp: "",
    country: "",
    city: "",
    password: "",
    confirmPassword: "",
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();
  const repository = new UserRepository();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = new Commerce(formData);
    let validationErrors = user.validateCommerce(formData, googleToken);

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    if (!terms) {
      setError("Debes aceptar los términos y condiciones");
      return;
    }

    try {
      setFieldErrors({});
      const response = await repository.registerCommerce(formData);

      if (response.success) {
        setError(null);
        await navigate("/", {
          state: {
            showMessage: true,
            message:
              "Te hemos enviado un correo electrónico con un enlace de activación. Por favor, revisa tu correo para activar la cuenta",
          },
        });
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    setTerms(event.target.checked);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setAreCategoriesLoading(true);
      try {
        const response = await categoriesRepository.getCategories(
          i18n.language
        );
        setCategories(response?.map((category) => category));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
      setAreCategoriesLoading(false);
    };
    fetchCategories();
  }, [i18n.language]);

  useEffect(() => {
    const fetchCellars = async () => {
      setAreCellarsLoading(true);
      try {
        const response = await cellarRepository.getCellarList();
        setCellars(response?.map((cellar) => cellar));
      } catch (error) {
        console.error("Error fetching cellars:", error);
      }
      setAreCellarsLoading(false);
    };
    fetchCellars();
  }, []);

  return (
    <Container sx={{ marginBottom: "2rem" }}>
      {areCellarsLoading || areCategoriesLoading ? null : (
        <>
          <Typography
            variant="h3"
            style={{ color: "#F36E1E", fontSize: "35px", fontWeight: "bold" }}
            align="center"
            mt={10}
            mb={12}
          >
            {t("registerForm.title")}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.companyName")}
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.businessName)}
                  error={Boolean(fieldErrors.businessName)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.taxId")}
                  name="tax"
                  value={formData.tax}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.tax)}
                  error={Boolean(fieldErrors.tax)}
                />
              </Grid>

              {/*<Grid item xs={12} sm={6}>
                                <TextField fullWidth label={t('registerForm.name')} name="name" value={formData.name} onChange={handleInputChange} InputProps={{style: {borderRadius: '55px'}}} helperText={fieldErrors.name} error={Boolean(fieldErrors.name)} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label={t('registerForm.surname')} name="surname" value={formData.surname} onChange={handleInputChange} InputProps={{style: {borderRadius: '55px'}}} helperText={fieldErrors.surname} error={Boolean(fieldErrors.surname)} />
                            </Grid>
                                    
                            <Grid item xs={12} sm={6}>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth type="date" label={t('registerForm.birthdate')} name="birthdate" value={formData.birthdate} onChange={handleInputChange} InputLabelProps={{ shrink: true }} InputProps={{style: {borderRadius: '55px'}}} helperText={fieldErrors.birthdate} error={Boolean(fieldErrors.birthdate)} />
                            </Grid>*/}

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel sx={{ background: "white" }}>
                    {t("registerForm.cellars")}
                  </InputLabel>
                  <Select
                    name="cellars"
                    value={formData.cellars}
                    onChange={handleSelectChange}
                    multiple
                    input={
                      <OutlinedInput
                        style={{
                          borderRadius: "55px",
                        }}
                      />
                    }
                    helperText={t(fieldErrors.cellars)}
                    error={Boolean(fieldErrors.cellars)}
                  >
                    {cellars?.map((cellar, index) => (
                      <MenuItem key={cellar.id} value={cellar.id}>
                        {cellar.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel sx={{ background: "white" }}>
                    {t("registerForm.category")}
                  </InputLabel>
                  <Select
                    name="category"
                    value={formData.category}
                    onChange={handleSelectChange}
                    input={
                      <OutlinedInput
                        style={{
                          borderRadius: "55px",
                        }}
                      />
                    }
                    helperText={t(fieldErrors.category)}
                    error={Boolean(fieldErrors.category)}
                  >
                    {categories?.map((category, index) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.address")}
                  name="residence"
                  value={formData.residence}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.residence)}
                  error={Boolean(fieldErrors.residence)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.zip")}
                  name="cp"
                  value={formData.cp}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.cp)}
                  error={Boolean(fieldErrors.cp)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.country")}
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.country)}
                  error={Boolean(fieldErrors.country)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.city")}
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.city)}
                  error={Boolean(fieldErrors.city)}
                />
              </Grid>

              {!googleUser && (
                <>
                  <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
                    <VisibilityIcon
                      onClick={() => {
                        setShowPassword1(!showPassword1);
                      }}
                      sx={{
                        cursor: "pointer",
                        zIndex: "2",
                        position: "absolute",
                        top: "39px",
                        right: "24px",
                        color: "#F36E1E",
                      }}
                    />
                    <TextField
                      fullWidth
                      type={showPassword1 ? "text" : "password"}
                      label={t("registerForm.password")}
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      InputProps={{ style: { borderRadius: "55px" } }}
                      helperText={
                        fieldErrors.password
                          ? typeof fieldErrors.password === String
                            ? t(fieldErrors.password)
                            : `${t("registerForm.password1")} 8 ${t(
                                "registerForm.password2"
                              )}`
                          : null
                      }
                      error={Boolean(fieldErrors.password)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
                    <VisibilityIcon
                      onClick={() => {
                        setShowPassword2(!showPassword2);
                      }}
                      sx={{
                        cursor: "pointer",
                        zIndex: "2",
                        position: "absolute",
                        top: "39px",
                        right: "24px",
                        color: "#F36E1E",
                      }}
                    />
                    <TextField
                      fullWidth
                      type={showPassword2 ? "text" : "password"}
                      label={t("registerForm.confPassword")}
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      InputProps={{ style: { borderRadius: "55px" } }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="email"
                  label={t("registerForm.email")}
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.email)}
                  error={Boolean(fieldErrors.email)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t("registerForm.phone")}
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={t(fieldErrors.phone)}
                  error={Boolean(fieldErrors.phone)}
                />
                <Typography
                  variant="h3"
                  style={{ margin: "0.25rem", fontSize: "13px" }}
                >
                  {t("registerForm.phoneText")}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.acceptTerms}
                      onChange={handleCheckboxChange}
                      name="acceptTerms"
                    />
                  }
                  label={t("registerForm.acceptTerms")}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="flex-start">
                <Button
                  type="submit"
                  variant="contained"
                  className={"btn-gradient"}
                >
                  {t("registerForm.registerButton")}
                </Button>
              </Grid>
            </Grid>
          </form>

          <ErrorDialog
            open={!!error}
            onClose={() => setError(null)}
            alertTitle={t("registerForm.registrationError")}
            alertMessage={error}
            dark={false}
          />
        </>
      )}
    </Container>
  );
};

export default RegisterCommerceForm;

import apiToken from "../apiToken";
import api from "../api";

class CollateralRepository {
  async getCollateralsFiltered(params = {}) {
    try {
      const filteredParams = Object.entries(params).reduce(
        (acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      const urlParams = new URLSearchParams(filteredParams).toString();
      const endpoint = urlParams
        ? `/marketplace/collaterals?${urlParams}`
        : "/marketplace/collaterals";

      const response = await api.get(endpoint);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async findById(id) {
    try {
      const response = await apiToken.get("/token/collateral/" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async putUpInSale(collateralId, price) {
    const formData = new FormData();
    formData.append("price", price);

    try {
      const response = await apiToken.post(
        `/profile/financial/collaterals/on-sale/${collateralId}`,
        formData
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error putting up collateral:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async cancelSale(collateralId) {
    try {
      const response = await apiToken.post(
        `/profile/financial/collaterals/remove-sale/${collateralId}`
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error putting down collateral:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async cancelInstallment(collateralId) {
    try {
      const response = await apiToken.post(
        `/profile/financial/collaterals/cancel-installment/${collateralId}`
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error burning collateral:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async transferCollateral(tokenId, cellarId) {
    const formData = new FormData();
    formData.append("tokenId", tokenId);
    formData.append("cellarId", cellarId);

    try {
      const response = await apiToken.post("/collaterals/transfer", formData);

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error transfering the collateral:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }

  async adquireCollateral(collateralId) {
    try {
      const response = await apiToken.post(
        `/token/collateral/buy/${collateralId}`
      );

      return { success: true, data: response.data };
    } catch (error) {
      console.error("Error adquiring collateral:", error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message
      };
    }
  }
}

export default CollateralRepository;

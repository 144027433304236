import React from 'react';
import Navbar from '../components/Navbar';
import { Whatsapp } from '../components/IconWhatsChat';

const DrinksifyLayout = (props) => {
  return (
    <main
      style={{
        position: 'relative',
        backgroundColor: props.theme === 'dark' ? '#160F31' : ''
      }}
    >
      <Navbar theme={props.theme} />
      <div className={`layout-container`}>{props.main}</div>
      <a href="https://wa.me/5492617146246" target="_blank" rel="noreferrer">
        <div className="chatWhats">
          <Whatsapp />
        </div>
      </a>
    </main>
  );
};

export default DrinksifyLayout;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CellarRepository from "../../data/repository/CellarRepository";
import { useTranslation } from "react-i18next";

const CellarSlider = () => {
  const [cellars, setCellars] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const repository = new CellarRepository();
  useEffect(() => {
    const fetchCellars = async () => {
      try {
        const response = await repository.getCellarList();
        setCellars(response?.map((cellar) => cellar));
      } catch (error) {
        console.error("Error fetching cellars:", error);
      }
    };

    fetchCellars();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (0 === cellars.length) {
    return (
      <Container id="bodegas" maxWidth="xl">
        <Box textAlign="center" mt={12} mb={12}></Box>
      </Container>
    );
  }

  return (
    <Container id="bodegas" maxWidth="xl" style={{ scrollMarginTop: "6rem" }}>
      <Box textAlign="center" mt={12} mb={12}>
        <Typography
          variant="h3"
          sx={{
            color: "white",
            fontSize: { xs: "20px", sm: "40px" },
            fontWeight: "bold",
          }}
          gutterBottom
        >
          {t("home.slider.title")}
        </Typography>

        <Box mx={2} mt={10} sx={{ width: "calc(100% - 2rem)" }}>
          <Slider gap={1} {...settings}>
            {cellars?.map((cellar, index) => (
              <img
                key={index}
                src={cellar.image}
                alt={`Slider-${index}`}
                onClick={() => navigate(`/marketplace?cellarId=${cellar.id}`)}
                style={{ cursor: "pointer", width: "80%" }}
              />
            ))}
          </Slider>
        </Box>
      </Box>
    </Container>
  );
};

export default CellarSlider;

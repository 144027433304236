import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import FooterComponent from "../components/FooterComponent";
import { useTranslation } from 'react-i18next';
import Cookies from "../components/Cookies";

const CookiesView = () => {
    const { t } = useTranslation();

    const {hash, key} = useLocation();

    useEffect(() => {
        if (hash) {
            const targetElement = document.getElementById(hash.substring(1));
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [key, hash]);

    return (
        <div>
            <Cookies />
            <FooterComponent />
        </div>
    )
}

export default CookiesView;